import { MapLayerMouseEvent } from 'mapbox-gl';
import {
  closeMenus,
  setVesselDossierOpen,
} from '../../../main-menu/menu.slice';
import store from '../../../store';
import { setSelectedVessel } from '../../../vessel-dossier/vessel-dossier.slice';
import MapHelpers from '../../map.utils';
import MapLayerIcon from '../map-layer-icon';
import MapLayer from '../map-layer.enum';

function onVesselClick(e: MapLayerMouseEvent, layer: MapLayer | string) {
  const map = e.target;
  const features = map.queryRenderedFeatures(e.point, {
    layers: [layer],
  });

  if (features?.length) {
    const feature = features[0];

    // clear current highlighting on vessels
    map.setLayoutProperty(
      layer,
      'icon-image',
      MapLayerIcon.PLAIN_VESSEL.NORMAL
    );
    MapHelpers.setLayerFeatureIcon(
      feature,
      MapLayerIcon.PLAIN_VESSEL.HIGHLIGHTED
    );

    // @ts-ignore
    // can't figure out the typing needed for coordinates
    map.flyTo({ center: feature.geometry.coordinates, duration: 1000 });
    store.dispatch(setSelectedVessel(feature));
    store.dispatch(setVesselDossierOpen(true));
    store.dispatch(closeMenus());
  }
}

export default onVesselClick;
