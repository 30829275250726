import { useState } from 'react';
import SimpleSearchBar from '../../../common-components/simple-search-bar/simple-search-bar';
import SimpleVesselTable from '../../../common-components/simple-vessel-table/simple-vessel-table';
import { AlertingVessel, AlertingVessels } from '../alertingVessels.model';
import './current-alerts.scss';

interface CurrentAlertsTableProps {
  alertingVessels: AlertingVessels[];
}

function CurrentAlerts(props: CurrentAlertsTableProps) {
  const [filterValue, setFilterValue] = useState('');

  const { alertingVessels } = props;

  const dataToDisplay = alertingVessels.map((vessel) => ({
    alertType: vessel.alertType,
    name: vessel.name,
    imo: vessel.imo,
    mmsi: vessel.mmsi,
  }));

  const handleSearchChange = (value: string) => {
    setFilterValue(value);
  };

  const CURRENT_ALERTS_TABLE_HEADERS = ['Alert', 'Name', 'IMO', 'MMSI'];

  return (
    <div className="current-alerts">
      <SimpleSearchBar
        handleChange={handleSearchChange}
        filterValue={filterValue}
      />

      <div className="current-alerts-alerting-table">
        <SimpleVesselTable
          columnHeaders={CURRENT_ALERTS_TABLE_HEADERS}
          data={dataToDisplay}
          dataFilter={(element: AlertingVessel) =>
            element.alertType
              .toLowerCase()
              .includes(filterValue.toLowerCase()) ||
            element.name.toLowerCase().includes(filterValue.toLowerCase()) ||
            element.imo.toString().includes(filterValue.toLowerCase()) ||
            element.mmsi.toString().includes(filterValue.toLowerCase())
          }
        />
      </div>
    </div>
  );
}

export default CurrentAlerts;
