import mapboxgl, { LngLatLike, MapLayerMouseEvent, Popup } from 'mapbox-gl';
import PopupHelpers from '../../../popup/popup.utils';
import DateTimeHelpers from '../../../utils/data-time-helpers.utils';

const POPUP_CLASSNAME = 'ais-data-gaps-popup';

const fromPopup = new Popup({
  closeButton: false,
  closeOnMove: false,
  className: POPUP_CLASSNAME,
});

export const toPopup = new Popup({
  closeButton: false,
  closeOnMove: false,
  className: POPUP_CLASSNAME,
});

const linePopup = new Popup({
  closeButton: false,
  closeOnMove: true,
});

export function hideAISDataGapsPopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = '';
  linePopup.remove();
}

export function showAISDataGapLinePopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = 'pointer';

  if (e.features?.length) {
    const feature = e.features[0];
    const { properties } = feature;

    PopupHelpers.createInfoCard(linePopup, [
      {
        label: 'MMSI',
        value: properties?.mmsi,
      },
      {
        label: 'Hours Offline',
        value: Math.floor(
          Number(properties?.duration) / DateTimeHelpers.MILLISECONDS_IN_HOUR
        ),
      },
    ]);

    // @ts-ignore
    // can't figure out the typing needed for coordinates
    linePopup.setLngLat(e.lngLat);
    linePopup.addTo(e.target);
  }
}

export function hideAISDataGapPointPopups() {
  fromPopup.remove();
  toPopup.remove();
}

export function showAISDataGapPointPopups(
  feature: GeoJSON.Feature<GeoJSON.LineString>,
  map: mapboxgl.Map
) {
  const { properties, geometry } = feature;

  PopupHelpers.createInfoCard(
    fromPopup,
    [
      {
        label: 'MMSI',
        value: properties?.mmsi,
      },
      {
        label: 'Last Broadcast',
        value: `<br>${new Date(properties?.startTimestamp)
          .toISOString()
          .replace('T', ' ')
          .substring(0, 19)}`,
      },
    ],
    'Start of Gap'
  );

  PopupHelpers.createInfoCard(
    toPopup,
    [
      {
        label: 'Hours Offline',
        value: Math.floor(
          Number(properties?.duration) / DateTimeHelpers.MILLISECONDS_IN_HOUR
        ),
      },
      {
        label: 'Next Broadcast',
        value: `<br>${new Date(properties?.endTimestamp)
          .toISOString()
          .replace('T', ' ')
          .substring(0, 19)}`,
      },
    ],
    'End of Gap'
  );

  const { coordinates } = geometry;

  fromPopup.setLngLat(coordinates[0] as LngLatLike);
  fromPopup.addTo(map);

  toPopup.setLngLat(coordinates[1] as LngLatLike);
  toPopup.addTo(map);
}
