/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  faArrowLeft,
  faArrowsLeftRightToLine,
  faCalendar,
  faCircleInfo,
  faClock,
  faFileExport,
  faFire,
  faLayerGroup,
  faShip,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import Accordion from '../../../common-components/accordian/accordian';
import HeatmapKey from '../../../common-components/heatmap-key/heatmap-key';
import IconButton from '../../../common-components/icon-button/icon-button';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import HistoryAISDataGaps from '../history-ais-data-gaps/history-ais-data-gaps';
import HistoryPanelLayerList from '../history-panel-layer-list';
import HistoryPanelSampleRate from '../history-panel-sample-rate';
import {
  HistoryPanelViewState,
  setHistoricVesselPoints,
  setViewState,
} from '../history-panel.slice';
import VesselHistoryController from '../vessel-history-controller.utils';
import VesselHistoryResultsExportModal from './history-results-export/history-results-export-modal';
import HistoryResultsList from './history-results-list';

import './history-results-view.scss';

export const MAX_IDENTIFIERS_SHOW_LIMIT = 3;

function HistoryResultsView() {
  const { formValues } = useAppSelector((state) => state.historyPanel);
  const dispatch = useAppDispatch();
  const [showExportModal, setShowExportModal] = useState<boolean>(false);

  // TODO: move to date helpers
  const formatDate = (date: string) => {
    const d = new Date(date);
    const day = d.getDate();
    const month = d.toLocaleString('default', { month: 'long' });
    const year = d.getFullYear();

    return `${month} ${day} ${year}`;
  };

  const SAMPLE_RATE_LABELS = {
    [HistoryPanelSampleRate.ONE_DAY]: 'Daily',
    [HistoryPanelSampleRate.ONE_HOUR]: 'Hourly',
    [HistoryPanelSampleRate.TEN_MINUTES]: 'Every 10 minutes',
  };

  // Max Number of IMO's / MMSI's to show

  const identifiersList = formValues.identifiers.split(' ');
  const IDENTIFIERS_TEXT = identifiersList.join(',  ');
  const IDENTIFIERS_TEXT_TRUNCATED = identifiersList
    .slice(0, MAX_IDENTIFIERS_SHOW_LIMIT)
    .join(', ');

  const onBackToSearch = () => {
    VesselHistoryController.clearAllHistoryLayers();
    dispatch(setHistoricVesselPoints([]));
    dispatch(setViewState(HistoryPanelViewState.SEARCH));
  };

  return (
    <div
      className="history-panel-results-view"
      data-testid="history-panel-results-view"
    >
      <div
        className="history-panel-back"
        onClick={() => {
          onBackToSearch();
        }}
        role="button"
      >
        <FontAwesomeIcon
          icon={faArrowLeft}
          className="history-panel-back-button"
          title="Back to Search"
        />
        <span className="history-panel-results-label">Back to Search</span>
      </div>
      <div className="history-panel-results-stats">
        <div className="history-panel-results-identifiers">
          <FontAwesomeIcon icon={faCircleInfo} title="Identifiers" />
          <span
            className="history-panel-results-label"
            title={
              identifiersList.length > MAX_IDENTIFIERS_SHOW_LIMIT
                ? IDENTIFIERS_TEXT
                : ''
            }
            data-testid="history-panel-results-identifiers"
          >
            {identifiersList.length > MAX_IDENTIFIERS_SHOW_LIMIT
              ? `${IDENTIFIERS_TEXT_TRUNCATED}...`
              : IDENTIFIERS_TEXT}
          </span>
        </div>
        <div className="history-panel-results-date">
          <FontAwesomeIcon icon={faCalendar} title="Date Range" />
          <span
            className="history-panel-results-label"
            data-testid="history-panel-results-date"
          >
            {formatDate(formValues.fromDate)} - {formatDate(formValues.toDate)}
          </span>
        </div>
        <div className="history-panel-results-sample-rate">
          <FontAwesomeIcon icon={faClock} title="Sample Rate" />
          <span
            className="history-panel-results-label"
            data-testid="history-panel-results-sample-rate"
          >
            {SAMPLE_RATE_LABELS[formValues.sampleRate]}
          </span>
        </div>
      </div>
      <div className="history-panel-results-sections">
        <IconButton
          classNames="history-panel-results-export"
          label="Export History"
          tooltip="Export history"
          iconDef={faFileExport}
          onClick={() => setShowExportModal(true)}
        />
        <Accordion
          title="Visualization Options"
          content={<HistoryPanelLayerList />}
          icon={faLayerGroup}
          defaultActive
        />
        <Accordion
          title="Results"
          content={<HistoryResultsList />}
          icon={faShip}
          defaultActive
        />
        <Accordion
          title="Investigate AIS Data Gaps"
          content={<HistoryAISDataGaps />}
          icon={faArrowsLeftRightToLine}
        />

        <Accordion
          title="Heatmap Key"
          content={<HeatmapKey label="Increasing Density of AIS Broadcasts" />}
          icon={faFire}
        />
        <VesselHistoryResultsExportModal
          showModal={showExportModal}
          closeModal={() => setShowExportModal(false)}
        />
      </div>
    </div>
  );
}

export default HistoryResultsView;
