import { jsonToCSV } from 'react-papaparse';

namespace DownloadHelpers {
  export const FileFormat = {
    JSON: 'json',
    CSV: 'csv',
  };

  export const FileMimeType = {
    JSON: 'application/json',
    CSV: 'text/csv',
  };

  export const createDownloadLink = (data: string, mimeType: string) => {
    const blob = new Blob([data], {
      type: mimeType,
    });

    return URL.createObjectURL(blob);
  };

  export const exportAs = {
    JSON: (data: {}) =>
      createDownloadLink(JSON.stringify(data), FileMimeType.JSON),
    CSV: (data: {}[]) => createDownloadLink(jsonToCSV(data), FileMimeType.CSV),
  };

  export const downloadURI = (filename: string, uri: string) => {
    const link = document.createElement('a');

    link.download = filename;
    link.href = uri;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}

export default DownloadHelpers;
