import MapLayerIcon from '../map-layer-icon';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';

function addPlainVesselLayerToMap(
  map: mapboxgl.Map,
  layer: MapLayer | string,
  metadata: object = {}
) {
  map.addLayer({
    id: layer,
    type: 'symbol',
    source: layer,
    metadata,
    layout: {
      visibility: MapLayerVisibility.VISIBLE,
      'icon-image': MapLayerIcon.PLAIN_VESSEL.NORMAL,
      'icon-allow-overlap': true,
      'icon-rotation-alignment': 'map',
      'icon-rotate': ['number', ['get', 'course'], 360],
      'icon-size': [
        // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#interpolate
        'interpolate',
        ['linear'],
        ['zoom'],
        // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#camera-expressions
        // map zoom level 2, circle radius is 0.25px
        2,
        0.25,
        // map zoom level 8, circle radius is 0.4px
        8,
        0.4,
        // map zoom level 16, circle radius is 0.5px
        16,
        0.5,
        // map zoom level 20, circle radius is 0.75px
        20,
        0.75,
      ],
    },
  });
}

export default addPlainVesselLayerToMap;
