/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/require-default-props */

import { useAppDispatch } from '../../../hooks';
import DateTimeHelpers from '../../../utils/data-time-helpers.utils';
import GeoHelper from '../../../utils/geo-helpers.utils';
import { setSelectedAISDataGapFeature } from '../history-panel.slice';
import AISDataGapsController from './ais-data-gaps-controller.utils';

/* eslint-disable jsx-a11y/click-events-have-key-events */
interface HistoryAISDataGapResultCardProps {
  feature: GeoHelper.TimeGapFeature;
  selected: boolean;
}

function HistoryAISDataGapResultCard(props: HistoryAISDataGapResultCardProps) {
  const { feature, selected } = props;
  const { properties } = feature;
  const { mmsi, startTimestamp, duration, id } = properties;

  const dispatch = useAppDispatch();

  const onAISDataGapResultClick = () => {
    AISDataGapsController.onFeatureSelect(feature);
    dispatch(setSelectedAISDataGapFeature(feature));
  };

  return (
    <div
      data-testid="history-ais-data-gap-result"
      className={`history-ais-data-gap-result ${selected ? 'selected' : ''}`}
      onClick={onAISDataGapResultClick}
      data-resultid={id}
    >
      <div>MMSI: {mmsi}</div>
      <div>
        From: {/* TODO: Move to Date Time Helpers */}
        {new Date(startTimestamp)
          .toISOString()
          .replace('T', ' ')
          .substring(0, 19)}
      </div>
      <div>
        Length:{' '}
        {duration &&
          Math.floor(
            Number(duration) / DateTimeHelpers.MILLISECONDS_IN_HOUR
          )}{' '}
        hour(s)
      </div>
    </div>
  );
}

export default HistoryAISDataGapResultCard;
