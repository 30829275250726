/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import './checkbox-table.scss';

interface CheckboxTableProps {
  columnHeaders: string[];
  data: any[];
  //   dataFilter: (item: any) => boolean;
  toggleSelectAll: () => void;
  rowCheckBoxClicked: (id: string) => void;
  selectedIds: string[];
  rowClicked: (id: string) => void;
}

function CheckboxTable(props: CheckboxTableProps) {
  const {
    columnHeaders,
    toggleSelectAll,
    rowCheckBoxClicked,
    data,
    selectedIds,
    rowClicked,
  } = props;

  return (
    <div>
      <table data-testid="checkbox-table" className="simple-checkbox-table">
        <thead>
          <tr>
            <th>
              <input
                data-testid="checkbox-table-select-all"
                className="checkbox-header"
                type="checkbox"
                checked={selectedIds.length === data.length}
                onChange={toggleSelectAll}
              />
            </th>
            {columnHeaders.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data && data.length > 0 ? (
            data.map((item: any) => (
              <tr key={item.id}>
                <td>
                  <input
                    data-testid={`checkbox-${item.id}`}
                    type="checkbox"
                    onChange={() => rowCheckBoxClicked(item.id)}
                    checked={selectedIds.some(
                      (selectedId: string) => selectedId === item.id
                    )}
                  />
                </td>

                {Object.keys(item)
                  .filter((key) => key !== 'id')
                  .map((key) => (
                    <td
                      data-testid={`row-${key}-${item.id}`}
                      key={`row-${item.id}`}
                      onClick={() => rowClicked(item.id)}
                    >
                      {item[key]}
                    </td>
                  ))}
              </tr>
            ))
          ) : (
            <tr key="0123">
              <td colSpan={columnHeaders.length + 1}>
                <p>No data provided.</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CheckboxTable;
