import { GridLoader } from 'react-spinners';
import './loading-panel.scss';

function LoadingPanel() {
  return (
    <div data-testid="loading-panel" className="loading-spinner">
      <GridLoader color="#2abbaf" loading size={20} />
    </div>
  );
}

export default LoadingPanel;
