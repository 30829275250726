/* eslint-disable jsx-a11y/label-has-associated-control */
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useAppSelector } from '../../../../hooks';
import './history-results-export-modal.scss';

import ErrorPanel from '../../../../common-components/error-components/error-panel/error-panel';
import IconButton from '../../../../common-components/icon-button/icon-button';
import SimpleModal from '../../../../common-components/simple-modal/simple-modal';
import DownloadHelpers from '../../../../utils/download.utils';
import GeoHelper from '../../../../utils/geo-helpers.utils';

function RenderHistoryResultsExportForm() {
  const { historicVesselPoints } = useAppSelector(
    (state) => state.historyPanel
  );

  const DEFAULT_FILENAME = 'vessel_history';

  const [options, setOptions] = useState({
    fileName: DEFAULT_FILENAME,
    fileFormat: DownloadHelpers.FileFormat.JSON,
  });

  const handleExport = () => {
    const { fileFormat, fileName } = options;

    if (!fileName.length) {
      return;
    }

    const FULL_FILENAME = `${fileName}.${fileFormat}`;

    const getFileContent = {
      [DownloadHelpers.FileFormat.JSON]: () =>
        DownloadHelpers.exportAs.JSON(
          GeoHelper.createGeoJSON(
            GeoHelper.vesselsToGeoJSONFeatures(historicVesselPoints)
          )
        ),
      [DownloadHelpers.FileFormat.CSV]: () =>
        DownloadHelpers.exportAs.CSV(historicVesselPoints),
    };

    DownloadHelpers.downloadURI(FULL_FILENAME, getFileContent[fileFormat]());
  };

  return (
    <div
      className="history-results-export-form"
      data-testid="history-results-export-form"
    >
      <div>
        Please Provide a File Name and Select a Format for your exported file.
      </div>
      <div className="history-results-export-input-section">
        <div>File Name:</div>
        <input
          data-testid="history-results-export-file-name"
          className={`history-results-export-input-field file-name ${
            !options.fileName.length && 'error-chip'
          }`}
          type="text"
          defaultValue={options.fileName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setOptions({ ...options, fileName: e.target.value })
          }
        />
      </div>
      <div className="history-results-export-input-section">
        <div>Format:</div>
        <select
          data-testid="history-results-export-file-format"
          className="history-results-export-input-field"
          defaultValue={options.fileFormat}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
            setOptions({ ...options, fileFormat: e.target.value })
          }
        >
          <option value={DownloadHelpers.FileFormat.JSON}>JSON</option>
          <option value={DownloadHelpers.FileFormat.CSV}>CSV</option>
        </select>
      </div>
      {!options.fileName.length && (
        <ErrorPanel
          message="Please provide a valid filename."
          messageColour="black"
        />
      )}
      <div className="history-results-export-controls">
        <IconButton
          classNames="history-results-export-button"
          iconDef={faFileExport}
          label="Export"
          tooltip="Export history"
          onClick={handleExport}
        />
      </div>
    </div>
  );
}

interface HistoryResultsExportModalProps {
  showModal: boolean;
  closeModal: () => void;
}

function HistoryResultsExportModal(props: HistoryResultsExportModalProps) {
  const { showModal, closeModal } = props;

  return (
    <SimpleModal
      closeModal={closeModal}
      title="Export Vessel History"
      showModal={showModal}
      className="history-results-export-modal"
      content={<RenderHistoryResultsExportForm />}
    />
  );
}

export default HistoryResultsExportModal;
