import { LngLatLike, MapLayerMouseEvent } from 'mapbox-gl';
import MapLayer from '../../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../../map/map.utils';
import store from '../../../store';
import DateTimeHelpers from '../../../utils/data-time-helpers.utils';
import GeoHelper from '../../../utils/geo-helpers.utils';
import {
  INITIAL_HISTORY_PANEL_STATE,
  setAISDataGapLength,
  setSelectedAISDataGapFeature,
} from '../history-panel.slice';
import {
  hideAISDataGapPointPopups,
  hideAISDataGapsPopUp,
  showAISDataGapLinePopUp,
  showAISDataGapPointPopups,
  toPopup,
} from './ais-data-gaps-popup';

namespace AISDataGapsController {
  export const getFilteredAISDataGapFeatures = () => {
    const { historicVesselPoints, aisDataGapLength } =
      store.getState().historyPanel;

    return GeoHelper.generateTimeGapData(
      historicVesselPoints,
      aisDataGapLength * DateTimeHelpers.MILLISECONDS_IN_HOUR
    );
  };

  export const onFeatureSelect = (
    feature: GeoJSON.Feature<GeoJSON.LineString>
  ) => {
    const map = MapHelpers.getMapInstance();
    const isLayerVisible = MapHelpers.isLayerVisible(
      MapLayer.VESSEL_HISTORY_AIS_DATA_GAPS
    );

    if (isLayerVisible) {
      showAISDataGapPointPopups(feature, map);
    }

    MapHelpers.zoomToLine(
      feature.geometry.coordinates[0] as LngLatLike,
      feature.geometry.coordinates[1] as LngLatLike
    );
  };

  export const layerEvents = {
    onMouseEnter: showAISDataGapLinePopUp,
    onMouseLeave: hideAISDataGapsPopUp,
    onClick: (e: MapLayerMouseEvent) => {
      if (e.features?.length) {
        const rawFeature = e.features[0] as GeoJSON.Feature<GeoJSON.LineString>;
        const filteredFeatures = getFilteredAISDataGapFeatures();
        const geoJSONFeature = filteredFeatures.features.find(
          (feature) => feature.properties.id === rawFeature.properties?.id
        );

        if (geoJSONFeature) {
          AISDataGapsController.onFeatureSelect(geoJSONFeature);
          store.dispatch(setSelectedAISDataGapFeature(geoJSONFeature));
        }
      }

      e.preventDefault();
    },
  };

  export const clearSelectedFeature = () => {
    store.dispatch(setSelectedAISDataGapFeature(null));
  };

  export const resetToDefaults = () => {
    clearSelectedFeature();

    store.dispatch(
      setAISDataGapLength(INITIAL_HISTORY_PANEL_STATE.aisDataGapLength)
    );
  };

  export const addPopupEventListeners = () => {
    clearSelectedFeature();
  };

  export const onAISDataGapsLayerVisibilityChange = () => {
    // remove the Pop up Close Event Listener
    // prevent feature being de-selected once layer visibility changes
    toPopup.off('close', addPopupEventListeners);

    const isAISDataGapsLayerVisible = MapHelpers.isLayerVisible(
      MapLayer.VESSEL_HISTORY_AIS_DATA_GAPS
    );
    const { selectedAISDataGapFeature } = store.getState().historyPanel;
    const map = MapHelpers.getMapInstance();

    if (isAISDataGapsLayerVisible && selectedAISDataGapFeature) {
      showAISDataGapPointPopups(
        selectedAISDataGapFeature as GeoJSON.Feature<GeoJSON.LineString>,
        map
      );
    } else if (!isAISDataGapsLayerVisible) {
      hideAISDataGapPointPopups();
    }
  };
}

export default AISDataGapsController;
