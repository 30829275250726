export interface HistoryFormValues {
  fromDate: string;
  toDate: string;
  sampleRate: string;
  identifiers: string;
}

export const checkDuplicateIds = (identifiers: string) => {
  const identifiersArray: string[] = identifiers.trim().split(/\s+/);
  const duplicates = identifiersArray.filter(
    (item, index) => identifiersArray.indexOf(item) !== index
  );

  return new Set(duplicates);
};

export const validateIdentifiers = (identifiers: string) => {
  const identifiersArray: string[] = identifiers.trim().split(/\s+/);
  const malformedIds: string[] = [];
  const IMO_LENGTH = 7;
  const MMSI_LENGTH = 9;

  identifiersArray.forEach((id: string) => {
    if (
      (id.length === 0 ||
        id.length === IMO_LENGTH ||
        id.length === MMSI_LENGTH) &&
      id.match('[0-9]+')
    ) {
      return;
    }
    malformedIds.push(id);
  });

  return malformedIds;
};

export const validateForm = (values: HistoryFormValues) => {
  const errors: any = {};
  if (!values.fromDate) {
    errors.fromDate = 'Required';
  } else if (new Date(values.fromDate) > new Date(values.toDate)) {
    errors.fromDate = `The 'from date' must be before the 'to date'.`;
  }
  if (!values.toDate) {
    errors.toDate = 'Required';
  }

  if (values.identifiers) {
    const duplicateIds = checkDuplicateIds(values.identifiers);
    const malformedIds = validateIdentifiers(values.identifiers);

    if (malformedIds.length) {
      errors.identifiers =
        'The following Ids are malformed, Ids must be 7 or 9 numeric characters long: ';
      malformedIds.forEach((id: string) => {
        // eslint-disable-next-line prefer-template
        errors.identifiers += id + ' ';
      });
    } else if (duplicateIds.size) {
      errors.identifiers =
        'The following Ids are duplicated, please only insert the ID once: ';
      duplicateIds.forEach((id: string) => {
        // eslint-disable-next-line prefer-template
        errors.identifiers += id + ' ';
      });
    }
  } else {
    errors.identifiers = 'Required';
  }
  return errors;
};
