import mapboxgl from 'mapbox-gl';

namespace MapLayerPaint {
  export const DEFAULT_HEATMAP_GRADIENT = [
    0,
    'rgba(13,8,135,0)',
    0.14,
    'rgb(84,2,163)',
    0.29,
    'rgb(139,10,165)',
    0.43,
    'rgb(185,50,137)',
    0.57,
    'rgb(219,92,104)',
    0.71,
    'rgb(244,136,73)',
    0.86,
    'rgb(254,188,43)',
    1,
    'rgb(240,249,33)',
  ];

  export const DEFAULT_HEATMAP: mapboxgl.HeatmapPaint = {
    'heatmap-intensity': 1,
    'heatmap-radius': {
      stops: [
        [1, 10],
        [22, 20],
      ],
    },
    // heatmap from purple to yellow
    // purple - least dense areas
    // yellow - most dense areas
    'heatmap-color': [
      'interpolate',
      ['linear'],
      ['heatmap-density'],
      ...DEFAULT_HEATMAP_GRADIENT,
    ],
    // decrease opacity to transition into the circle layer
    // Transition from heatmap to circle layer by zoom level
    'heatmap-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      8,
      1, // at Zoom level 8, it's full opaque.
      9,
      0, // at Zoom level 9 it's complete transparent
    ],
  };

  export const VESSEL_HISTORY_JOURNEY: mapboxgl.LinePaint = {
    'line-color': '#fbff00',
    'line-width': 2,
  };

  export const HIGHLIGHTED_LINES: mapboxgl.LinePaint = {
    'line-color': '#F88',
    'line-width': 4,
  };

  export const OPEN_SEA_MAP_BANNER: mapboxgl.FillPaint = {
    'fill-color': '#ff0000',
    'fill-opacity': 0.7,
  };
}

export default MapLayerPaint;
