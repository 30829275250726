import { faCircleXmark, faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useAppDispatch, useAppSelector } from '../hooks';
import {
  EMenuItems,
  setSelectedOption,
  toggleVesselDossier,
} from '../main-menu/menu.slice';
import MapLayerIcon from '../map/map-layer-manager/map-layer-icon';

import MapHelpers from '../map/map.utils';
import {
  HistoryPanelViewState,
  setFormValues,
  setViewState,
} from '../maritime-menu-options/history-panel/history-panel.slice';
// import 'react-tabs/style/react-tabs.css';
// import VESSEL_INFORMATION_STUB from '../mocks/stubs/vessel-dossier.mock';
import './vessel-dossier.scss';
import VesselInformation from './vessel-information/vessel-information';

const mountedStyle = {
  animation: 'inAnimation 270ms ease-in',
};
const unmountedStyle = {
  animation: 'outAnimation 270ms ease-out',
  animationFillMode: 'forwards',
};

function VesselDossier() {
  const dispatch = useAppDispatch();
  const [isMounted, setIsMounted] = useState(true);
  const selectedVessel = useAppSelector(
    (state) => state.vesselDossier.selectedVessel
  );

  const { formValues, historicVesselPoints } = useAppSelector(
    (state) => state.historyPanel
  );

  const onClose = () => {
    if (selectedVessel.layer) {
      MapHelpers.setLayerFeatureIcon(
        selectedVessel,
        MapLayerIcon.PLAIN_VESSEL.NORMAL
      );
    }
  };

  const goToVesselHistory = () => {
    const identifier = selectedVessel.properties?.mmsi;
    const showVesselHistoryResults = historicVesselPoints.some(
      (point) => point.mmsi === identifier
    );
    const vesselHistoryViewState = showVesselHistoryResults
      ? HistoryPanelViewState.RESULTS
      : HistoryPanelViewState.SEARCH;

    // auto fill identifiers section of Vessel History Form if not found in results
    if (!showVesselHistoryResults) {
      dispatch(
        setFormValues({
          ...formValues,
          identifiers: String(identifier),
        })
      );
    }

    onClose();
    dispatch(setViewState(vesselHistoryViewState));
    dispatch(setSelectedOption(EMenuItems.HISTORY));
    dispatch(toggleVesselDossier());
  };

  return (
    <div
      data-testid="vessel-dossier-container"
      className="vessel-dossier-container"
      style={isMounted ? mountedStyle : unmountedStyle}
      onAnimationEnd={() => {
        if (!isMounted) dispatch(toggleVesselDossier());
      }}
    >
      <div className="dossier-title">
        <h4 data-testid="dossier-title">Vessel Details: </h4>
        {selectedVessel.properties && <h3>{selectedVessel.properties.name}</h3>}
        <button
          data-testid="close-vessel-dossier-button"
          className="close-vessel-dossier-button"
          type="button"
          title="Close"
          onClick={() => {
            setIsMounted(false);
            onClose();
          }}
        >
          <FontAwesomeIcon
            className="close-panel-icon fa-2x"
            icon={faCircleXmark}
          />
        </button>
      </div>
      <div>
        <Tabs>
          <TabList>
            <Tab data-testid="information-tab">Information</Tab>
            <Tab data-testid="graphs-tab">Graphs</Tab>
            <Tab data-testid="discussions-tab">Discussions</Tab>
          </TabList>

          <TabPanel>
            <VesselInformation />
          </TabPanel>
          <TabPanel>
            <h2>Charts</h2>
          </TabPanel>
          <TabPanel>
            <h2>Discussions Placeholder</h2>
          </TabPanel>
        </Tabs>
      </div>
      <div className="vessel-dossier-options">
        <button
          data-testid="see-vessel-history-button"
          className="see-vessel-history-button"
          type="button"
          title="See Vessel History"
          onClick={goToVesselHistory}
        >
          <FontAwesomeIcon className="see-history-icon fa-1x" icon={faClock} />
          <p>History</p>
        </button>
      </div>
    </div>
  );
}

export default VesselDossier;
