import { MapLayerMouseEvent, Popup } from 'mapbox-gl';
import { AlertingVessel } from '../../../maritime-menu-options/alert-panel/alertingVessels.model';
import PopupHelpers from '../../../popup/popup.utils';

const popup = new Popup({
  closeButton: false,
  closeOnMove: true,
});

export function hideVesselInfoPopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = '';
  popup.remove();
}

export function showVesselInfoPopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = 'pointer';

  if (e.features?.length) {
    const feature = e.features[0];
    const properties = feature.properties as AlertingVessel;
    const { name, imo, mmsi, positionTimestamp } = properties;

    PopupHelpers.createInfoCard(popup, [
      {
        label: 'Name',
        value: name,
      },
      {
        label: 'IMO',
        value: imo,
      },
      {
        label: 'MMSI',
        value: mmsi,
      },
      {
        label: '',
        value: positionTimestamp,
      },
    ]);

    // @ts-ignore
    // can't figure out the typing needed for coordinates
    popup.setLngLat(feature.geometry.coordinates);
    popup.addTo(e.target);
  }
}
