/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { faHome, faLandmark, faShip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { EAdminMenuItems } from '../admin.slice';

import './options-menu.scss';

function OptionsMenu() {
  const [selectedOption, setSelectedOption] = useState<EAdminMenuItems>(
    EAdminMenuItems.COMPANIES
  );

  return (
    <div className="options-menu-container">
      <h4>Admin Options</h4>
      <ul>
        <Link to="/">
          <li>
            <FontAwesomeIcon className="cms-options-icon" icon={faHome} />
            Back to map
          </li>
        </Link>
        <Link to="/admin/companies">
          <li
            data-testid="companies-menu-option"
            className={`${
              selectedOption === EAdminMenuItems.COMPANIES
                ? 'admin-selected'
                : ''
            }`}
            onClick={() => setSelectedOption(EAdminMenuItems.COMPANIES)}
          >
            <FontAwesomeIcon className="cms-options-icon" icon={faLandmark} />
            Companies
          </li>
        </Link>

        <Link to="/admin/vessels">
          <li
            data-testid="vessels-menu-option"
            className={`${
              selectedOption === EAdminMenuItems.VESSELS ? 'admin-selected' : ''
            }`}
            onClick={() => setSelectedOption(EAdminMenuItems.VESSELS)}
          >
            <FontAwesomeIcon className="cms-options-icon" icon={faShip} />
            Vessels
          </li>
        </Link>
      </ul>
    </div>
  );
}

export default OptionsMenu;
