export const DESIGN_LABELS = [
  'Is Coated',
  'Is Gearless',
  'Is Self Unloading',
  'Gear Display',
  'Gear Max Swl',
  'Reefer Point Count',
  'Hull Type Code',
];

export const ENGINE_LABELS = [
  'Main Engine Count',
  'Main Engine Designer',
  'Propulsion Type',
  'Engine Designation',
  'MCO RPM',
  'MCO KW',
  'MCO HP',
  'Propeller Count',
  'Propeller Type',
  'Bow Thruster Count',
  'Stern Thruster Count',
];

export const DIMENSION_LABELS = [
  'Draught',
  'Overall Length',
  'Air Draught',
  'Keel to Manifold',
  'Depth',
  'Beam Moulded',
  'Berth Count',
];

export const CAPACITY_LABELS = [
  'Dead Weight',
  'TPCMI',
  'Net Tonnage',
  'Gross Tonnage',
  'Displacement',
  'Liquid Cubic 98%',
  'Grain Cubic Capacity',
  'TEU',
  'Hold Count',
  'Hold Dimensions',
  'Hatch Count',
  'Hatch Dimensions',
  'FEU',
  'TEU Surplus',
  'TEU 14t',
  'Lane Meters',
  'Cars',
  'Passengers',
  'Reefer Cubic',
];

export const REGISTRATION_LABELS = [
  'Class 1 Code',
  'Class 2 Code',
  'Class Details',
  'Is Ice Classed',
  'Ice Class',
  'Certificates',
];

export const BUILD_HISTORY_LABELS = [
  'Name Date',
  'Build Year',
  'Dead Year',
  'Ship Builder',
  'Hull Number',
  'Registered Owner',
  'Keel Laid Year',
  'Launch Year',
  'Commercial Owner',
];
