/* eslint-disable jsx-a11y/aria-role */
import {
  faLandmark,
  faTrash,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckboxTable from '../../../../common-components/checkbox-table/checkbox-table';
import DeleteModal from '../../../../common-components/delete-modal/delete-modal';
import ErrorPanel from '../../../../common-components/error-components/error-panel/error-panel';
import IconButton from '../../../../common-components/icon-button/icon-button';
import LoadingPanel from '../../../../common-components/loading-panel/loading-panel';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { setUsers, setUsersError, setUsersLoading } from '../../admin.slice';

import './company-view.scss';

function mapToTableObjects(users: any[] | null) {
  let detailsToDisplay: Object[] = [];
  if (users) {
    detailsToDisplay = users.map((user) => ({
      id: user.user_id,
      name: `${user.first_name} ${user.last_name}`,

      email: user.email,
    }));
  }
  return detailsToDisplay;
}

function CompanyView() {
  const GEONIUS_BACKEND_URL_STEM = process.env.REACT_APP_GEONIUS_BACKEND_URL;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const company: any = useAppSelector((state) => state.admin.selectedCompany);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [deleteLoading, setShowDeleteLoading] = useState<boolean>(false);
  const [deleteError, setShowDeleteError] = useState<boolean>(false);
  const [deleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);

  const loadingUsers = useAppSelector((state) => state.admin.usersLoading);
  const errorUsers = useAppSelector((state) => state.admin.usersError);

  const users: any = useAppSelector((state) => state.admin.users);
  const dataToDisplay = mapToTableObjects(users);

  useEffect(() => {
    dispatch(setUsersLoading());
    axios
      .get(
        `${GEONIUS_BACKEND_URL_STEM}/users?company_id=${company.company_id}`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .then((response) => {
        dispatch(setUsers(response.data));
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_error) => {
        dispatch(setUsersError());
      });
  }, []);

  const onDeleteUsers = () => {
    setShowDeleteLoading(true);
    axios
      .delete(`${GEONIUS_BACKEND_URL_STEM}/users`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          user_ids: selectedIds,
        },
      })
      .then(() => {
        setShowDeleteLoading(false);
        setShowDeleteSuccess(true);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_error) => {
        setShowDeleteLoading(false);
        setShowDeleteError(true);
      });
  };

  const modifyselectedIds = (id: string) => {
    // if compnay is in the selected company list already then delete it
    if (selectedIds.some((selectedId: string) => selectedId === id)) {
      setSelectedIds((prevSelectedIds: string[]) =>
        prevSelectedIds.filter(
          (previouslySelectedId) => previouslySelectedId !== id
        )
      );
    } else {
      setSelectedIds((oldArray: string[]) => [...oldArray, id]);
    }
  };

  const toggleAllUsers = () => {
    if (selectedIds.length < users.length) {
      users.forEach((user: any) => {
        // if company isn't already a part of selectedIds then add it
        if (!selectedIds.some((id: string) => id === user.user_id)) {
          setSelectedIds((oldArray: string[]) => [...oldArray, user.user_id]);
        }
      });
    } else {
      setSelectedIds([]);
    }
  };

  const toggleShowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
    if (!showDeleteModal) {
      setShowDeleteLoading(false);
      setShowDeleteError(false);
      setShowDeleteSuccess(false);
    }
  };

  return (
    <div className="company-info-container">
      <div className="company-info-container-header">
        <IconButton
          label="Back to companies"
          tooltip="Go back to companies"
          iconDef={faLandmark}
          onClick={() => navigate(-1)}
          classNames="users-delete"
        />
        <div className="company-info-header-title">
          <h2>Company: {company.company_name}</h2>
        </div>
      </div>

      <div className="company-view-container">
        <div className="features-container"> features-todo</div>
        <div className="users-container">
          <h3>Users</h3>
          <div className="users-container-buttons">
            <IconButton
              label="Delete"
              tooltip="Delete users"
              iconDef={faTrash}
              onClick={toggleShowDeleteModal}
              classNames="users-delete"
              disabled={selectedIds.length === 0}
            />
            <IconButton
              label="Upload"
              tooltip="Upload users"
              iconDef={faUpload}
              onClick={() => {}}
              classNames=""
            />
          </div>
          <div className="users-container-table">
            {loadingUsers && <LoadingPanel />}
            {errorUsers && !loadingUsers && (
              <ErrorPanel
                message="Error loading users..."
                messageColour="white"
              />
            )}
            {!loadingUsers && !errorUsers && users && (
              <div className="all-users-table">
                <CheckboxTable
                  columnHeaders={['Name', 'Email']}
                  toggleSelectAll={toggleAllUsers}
                  rowCheckBoxClicked={modifyselectedIds}
                  data={dataToDisplay}
                  selectedIds={selectedIds}
                  rowClicked={() => {}}
                />
                <DeleteModal
                  loading={deleteLoading}
                  error={deleteError}
                  success={deleteSuccess}
                  visible={showDeleteModal}
                  onClose={toggleShowDeleteModal}
                  title="Delete Users"
                  onDelete={onDeleteUsers}
                  content={
                    <div>
                      <p>
                        This action will delete all data associated with the
                        User. This includes Drawings, preferences etc.
                      </p>
                      <span>
                        Are you sure you want to delete the following users?:
                      </span>
                      <ul role="delete-users-list">
                        {users
                          .filter((user: any) =>
                            selectedIds.some(
                              (selectedId: string) =>
                                selectedId === user.user_id
                            )
                          )
                          .map((user: any) => (
                            <li key={user.user_id}>
                              {`${user.first_name} ${user.last_name}`}
                            </li>
                          ))}
                      </ul>
                    </div>
                  }
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CompanyView;
