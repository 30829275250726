/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export enum EAdminMenuItems {
  COMPANIES = 'Companies',
  VESSELS = 'Vessels',
}

export interface VesselData {
  vessel_id: string;
  vessel_name: string;
  imo: string;
  current_mmsi: string;
  vessel_type: string;
  company_id: string;
}

export const INITIAL_ADMIN_STATE = {
  companiesLoading: false,
  companiesError: false,
  companies: null,
  allVesselsLoading: false,
  allVesselsError: false,
  allVessels: [],
  usersLoading: false,
  usersError: false,
  users: null,
  selectedOption: EAdminMenuItems.COMPANIES,
  selectedCompany: null,
};

export const AdminSlice = createSlice({
  name: 'admin',
  initialState: INITIAL_ADMIN_STATE,
  reducers: {
    setCompaniesLoading: (state) => {
      state.companiesLoading = true;
    },
    setCompaniesError: (state) => {
      state.companiesError = true;
      state.companiesLoading = false;
    },
    setCompanies: (state, action) => {
      state.companiesLoading = false;
      state.companiesError = false;
      state.companies = action.payload;
    },
    setUsersLoading: (state) => {
      state.usersLoading = true;
    },
    setUsersError: (state) => {
      state.usersError = true;
      state.usersLoading = false;
    },
    setUsers: (state, action) => {
      state.usersLoading = false;
      state.usersError = false;
      state.users = action.payload;
    },
    setAllVesselsLoading: (state, action) => {
      state.allVesselsLoading = action.payload;
    },
    setAllVesselsError: (state) => {
      state.allVesselsError = true;
      state.allVesselsLoading = false;
    },
    setAllVessels: (state, action) => {
      state.allVesselsLoading = false;
      state.allVesselsError = false;
      state.allVessels = action.payload;
    },
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  setCompaniesLoading,
  setCompaniesError,
  setCompanies,
  setAllVesselsLoading,
  setAllVesselsError,
  setAllVessels,
  setSelectedOption,
  setSelectedCompany,
  setUsers,
  setUsersError,
  setUsersLoading,
} = AdminSlice.actions;

export default AdminSlice.reducer;
