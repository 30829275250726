/* eslint-disable no-lonely-if */
import { useState } from 'react';
import ToggleSwitch from '../../../common-components/toggle-switch/toggle-switch';
import MapLayer from '../../../map/map-layer-manager/map-layer.enum';
import MapHelpers from '../../../map/map.utils';
import { EAlertTypes } from '../alertingVessels.model';

const DEFAULT_ALERTS: EAlertTypes[] = [EAlertTypes.AISO, EAlertTypes.ETW];

function AlertToggles() {
  const [activeFilters, setActiveFilters] =
    useState<EAlertTypes[]>(DEFAULT_ALERTS);

  const toggleAlertTypeVisibility = (
    alertType: EAlertTypes,
    visible: boolean
  ) => {
    const currentlyActiveFilters: EAlertTypes[] = [...activeFilters];

    if (!visible) {
      const index = currentlyActiveFilters.indexOf(alertType);
      currentlyActiveFilters.splice(index, 1);
    } else {
      currentlyActiveFilters.push(alertType);
    }

    setActiveFilters(currentlyActiveFilters);

    const map = MapHelpers.getMapInstance();
    map.setFilter(MapLayer.CURRENTLY_ALERTING_VESSELS, [
      'in',
      ['get', 'alertType'],
      ['literal', [...currentlyActiveFilters]],
    ]);
  };

  return (
    <div>
      <ToggleSwitch
        label="(AISO) AIS off"
        alertType={EAlertTypes.AISO}
        onToggle={toggleAlertTypeVisibility}
      />
      <ToggleSwitch
        label="ETW"
        alertType={EAlertTypes.ETW}
        onToggle={toggleAlertTypeVisibility}
      />
    </div>
  );
}

export default AlertToggles;
