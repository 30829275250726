import { HistoricVesselPoint } from '../maritime-menu-options/history-panel/historic-vessel-point.model';
import { Vessel } from '../models/vessel.model';

namespace GeoHelper {
  export const createFeaturePoint = (
    coordinates: [number, number],
    // ^^ longitude, latitude
    properties: GeoJSON.GeoJsonProperties
  ): GeoJSON.Feature<GeoJSON.Point> => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates,
    },
    properties,
  });

  export const createFeatureLineString = (
    coordinates: GeoJSON.Position[],
    properties: GeoJSON.GeoJsonProperties
  ): GeoJSON.Feature<GeoJSON.LineString> => ({
    type: 'Feature',
    properties,
    geometry: {
      type: 'LineString',
      coordinates,
    },
  });

  export const createGeoJSON = (
    features: GeoJSON.Feature[] = []
  ): GeoJSON.FeatureCollection => ({
    type: 'FeatureCollection',
    features,
  });

  export const vesselsToGeoJSONFeatures = (
    vessels: Vessel[]
  ): GeoJSON.Feature<GeoJSON.Point>[] =>
    vessels.map((vessel: Vessel) => {
      const { long, lat, ...properties } = vessel;

      return GeoHelper.createFeaturePoint([long, lat], properties);
    });

  export interface TimeGap extends Pick<Vessel, 'mmsi'> {
    id: number;
    startSpeed: number;
    endSpeed: number;
    startTimestamp: number;
    endTimestamp: number;
    duration: number;
  }

  export type TimeGapFeature = GeoJSON.Feature<GeoJSON.LineString, TimeGap>;

  // gap length in milliseconds - 1 day by default
  export const generateTimeGapData = (
    points: HistoricVesselPoint[],
    gapLength: number = 86400000
  ) => {
    const gapLines = [];

    for (let i = 0; i < points.length - 1; i += 1) {
      const firstPoint = points[i];
      const nextPoint = points[i + 1];

      if (firstPoint.mmsi === nextPoint.mmsi) {
        const firstPointTime = Date.parse(String(firstPoint.timestamp));
        const nextPointTime = Date.parse(String(nextPoint.timestamp));

        const timeDifference = nextPointTime - firstPointTime;

        if (timeDifference > gapLength) {
          gapLines.push(
            GeoHelper.createFeatureLineString(
              [
                [firstPoint.long, firstPoint.lat],
                [nextPoint.long, nextPoint.lat],
              ],
              {
                id: gapLines.length,
                mmsi: firstPoint.mmsi,
                startSpeed: firstPoint.speed,
                endSpeed: nextPoint.speed,
                startTimestamp: firstPointTime,
                endTimestamp: nextPointTime,
                duration: timeDifference,
              }
            )
          );
        }
      }
    }

    return GeoHelper.createGeoJSON(gapLines) as GeoJSON.FeatureCollection<
      GeoJSON.LineString,
      TimeGap
    >;
  };
}

export default GeoHelper;
