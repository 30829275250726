import { GeoJSONSource } from 'mapbox-gl';
import { Vessel } from '../../../models/vessel.model';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';

function setVesselFeatures(
  layerId: MapLayer | string,
  vesselFeatures: Vessel[]
) {
  const map = MapHelpers.getMapInstance();
  const features = GeoHelper.vesselsToGeoJSONFeatures(vesselFeatures);
  const geojson = GeoHelper.createGeoJSON(features);
  (map.getSource(layerId) as GeoJSONSource)?.setData(geojson);
}

export default setVesselFeatures;
