/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HistoricVesselPoint } from './historic-vessel-point.model';
import { HistoryFormValues } from './history-form/history-form-validators';
import HistoryPanelSampleRate from './history-panel-sample-rate';

export enum HistoryPanelViewState {
  SEARCH = 'search',
  RESULTS = 'results',
}

type HistoryPanelViewType =
  | HistoryPanelViewState.SEARCH
  | HistoryPanelViewState.RESULTS;

type IHistoryPanelState = {
  loading: boolean;
  error: boolean;
  selectedAISDataGapFeature: GeoJSON.Feature | null;
  aisDataGapLength: number;
  historicVesselPoints: HistoricVesselPoint[];
  viewState: HistoryPanelViewState;
  formValues: HistoryFormValues;
};

const FORMATTED_TODAY = new Date().toISOString().split('T')[0];

export const INITIAL_HISTORY_PANEL_STATE: IHistoryPanelState = {
  loading: false,
  error: false,
  selectedAISDataGapFeature: null,
  aisDataGapLength: 24,
  historicVesselPoints: [] as HistoricVesselPoint[],
  viewState: HistoryPanelViewState.SEARCH,
  formValues: {
    identifiers: '',
    toDate: FORMATTED_TODAY,
    fromDate: FORMATTED_TODAY,
    sampleRate: HistoryPanelSampleRate.ONE_HOUR,
  },
};

export const HistoryPanelSlice = createSlice({
  name: 'historyPanel',
  initialState: INITIAL_HISTORY_PANEL_STATE,
  reducers: {
    setSelectedAISDataGapFeature: (
      state,
      action: PayloadAction<GeoJSON.Feature | null>
    ) => {
      state.selectedAISDataGapFeature = action.payload;
    },
    setAISDataGapLength: (state, action: PayloadAction<number>) => {
      state.aisDataGapLength = action.payload;
    },
    setHistoricVesselPoints: (
      state,
      action: PayloadAction<HistoricVesselPoint[]>
    ) => {
      state.historicVesselPoints = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setViewState: (state, action: PayloadAction<HistoryPanelViewType>) => {
      state.viewState = action.payload;
    },
    setFormValues: (state, action: PayloadAction<HistoryFormValues>) => {
      state.formValues = action.payload;
    },
  },
});

export const {
  setHistoricVesselPoints,
  setLoading,
  setError,
  setViewState,
  setFormValues,
  setAISDataGapLength,
  setSelectedAISDataGapFeature,
} = HistoryPanelSlice.actions;

export default HistoryPanelSlice.reducer;
