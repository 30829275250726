import {
  hideAISPositionInfoPopUp,
  showAISPositionInfoPopup,
} from './ais-position-info-popup';

namespace AISVesselsController {
  export const layerEvents = {
    onMouseEnter: showAISPositionInfoPopup,
    onMouseLeave: hideAISPositionInfoPopUp,
  };
}

export default AISVesselsController;
