import axios from 'axios';
import { useEffect } from 'react';
import Accordion from '../../common-components/accordian/accordian';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import { useAppDispatch, useAppSelector } from '../../hooks';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import setVesselFeatures from '../../map/map-layer-manager/vessel-utils/set-vessel-features';

import './alert-panel.scss';
import AlertToggles from './alert-toggles/alert-toggles';
import {
  AlertData,
  AlertDataResponse,
  AlertingVessels,
  LocationData,
} from './alertingVessels.model';
import {
  setAlertingVessels,
  setError,
  setLoading,
} from './alertingVessels.slice';
import CurrentAlerts from './current-alerts/current-alerts';

const fetchData = async (dispatch: any) => {
  const GEONIUS_BACKEND_URL_STEM = process.env.REACT_APP_GEONIUS_BACKEND_URL;
  const MARITIME_HOT_URL_STEM = process.env.REACT_APP_MARITIME_HOT_URL;

  const alertData: AlertData[] = [];
  let mmsis: string[] = [];
  await axios
    .get(`${GEONIUS_BACKEND_URL_STEM}/alerting-vessels`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    })
    .then((response) => {
      response.data.forEach((alert: AlertDataResponse) => {
        alertData.push({
          alertType: alert.alert_type,
          alertTime: alert.alert_time,
          expiryTime: alert.expiry_time,
          mmsi: alert.current_mmsi,
          name: alert.vessel_name,
          type: alert.vessel_type,
          imo: alert.imo,
        });
      });

      mmsis = alertData.map(({ mmsi }) => mmsi);
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch((_error) => {
      dispatch(setError());
    });

  await axios
    .post(`${MARITIME_HOT_URL_STEM}/location_messages/last_known_locations`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        identifiers: {
          mmsis,
        },
      },
    })
    .then((response) => {
      const lastKnownLocations: LocationData[] = response.data;
      const alertingVessels: AlertingVessels[] = [];
      alertData.forEach((alert: AlertData) => {
        const location = lastKnownLocations.find(
          (vessel) => vessel.mmsi === alert.mmsi
        );

        if (location) {
          alertingVessels.push({
            alertType: alert.alertType,
            alertTime: alert.alertTime,
            expiryTime: alert.expiryTime,
            course: location.course,
            heading: location.heading,
            imo: location.imo,
            lat: location.lat,
            long: location.long,
            mmsi: location.mmsi,
            name: location.name,
          });
        }
      });

      dispatch(setAlertingVessels(alertingVessels));
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch((_error) => {
      dispatch(setError());
    });
};

function AlertPanel() {
  // const GEONIUS_BACKEND_URL_STEM = process.env.REACT_APP_GEONIUS_BACKEND_URL;
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.alertingVessels.loading);
  const hasError = useAppSelector((state) => state.alertingVessels.error);
  const alertingVessels = useAppSelector(
    (state) => state.alertingVessels.alertingVessels
  );

  const { mapInitialised } = useAppSelector((state) => state.map);

  useEffect(() => {
    if (mapInitialised && alertingVessels) {
      setVesselFeatures(MapLayer.CURRENTLY_ALERTING_VESSELS, alertingVessels);
    }
  }, [alertingVessels, mapInitialised]);

  useEffect(() => {
    if (!alertingVessels) {
      dispatch(setLoading());
    }
    fetchData(dispatch);
  }, []);

  return (
    <div className="alert-panel-container">
      <h3>Alerts</h3>
      <div className="alert-panel-container-content">
        {loading && <LoadingPanel />}
        {!loading && hasError && (
          <ErrorPanel
            message="Error: Unable to fetch alert data, please try again later..."
            messageColour="white"
          />
        )}

        {!loading && !hasError && alertingVessels && (
          <div
            data-testid="alerting-panel"
            className="alerts-panel-container-current-alerts"
          >
            <Accordion title="Alert Toggles" content={<AlertToggles />} />
            <Accordion
              title="Alerting Vessels Table"
              content={<CurrentAlerts alertingVessels={alertingVessels} />}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AlertPanel;
