import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './simple-search-bar.scss';

interface SimpleTableProps {
  handleChange: (value: string) => void;
  filterValue: string;
}

function SimpleSearchBar(props: SimpleTableProps) {
  const { handleChange, filterValue } = props;
  return (
    <div className="search-filter-container">
      <input
        className="search-filter"
        type="text"
        placeholder="Filter the table..."
        onChange={(event) => handleChange(event.target.value)}
        value={filterValue}
      />
      <button
        data-testid="delete-filter-text"
        className="search-filter-delete-button"
        type="button"
        title="Remove filter text"
        onClick={() => {
          handleChange('');
        }}
      >
        <FontAwesomeIcon
          className="search-filter-button-icon fa-1x"
          icon={faTrash}
        />
      </button>
    </div>
  );
}

export default SimpleSearchBar;
