import mapboxgl from 'mapbox-gl';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';
import addSimpleGeoJsonSource from '../vessel-utils/add-simple-geojson-source';

export const DEFAULT_TEST_LAYER_LAYOUT = {
  visibility: MapLayerVisibility.NOT_VISIBLE,
  'text-field': [
    'format',
    ['upcase', ['get', 'name']],
    { 'font-scale': 1, 'text-color': '#ffffff' },
  ],
  'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  'text-anchor': 'center',
  'text-max-width': 1000,
};

export function addTextLayer(
  map: mapboxgl.Map,
  layer: MapLayer | string,
  layout: mapboxgl.SymbolLayout = DEFAULT_TEST_LAYER_LAYOUT as mapboxgl.SymbolLayout
) {
  // eslint-disable-next-line no-new
  new Promise((resolve) => {
    addSimpleGeoJsonSource(map, layer);
    map.addLayer({
      id: layer,
      type: 'symbol',
      source: layer,
      layout,
    });
    resolve(map);
  });
}
