/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import './accordian.scss';

interface IAccordianProps {
  title: string;
  content: any;
  // eslint-disable-next-line react/require-default-props
  icon?: IconProp;
  // eslint-disable-next-line react/require-default-props
  defaultActive?: boolean;
}

function Accordion(props: IAccordianProps) {
  const { title, content, icon, defaultActive } = props;
  const [isActive, setIsActive] = useState(defaultActive || false);

  return (
    <div className="accordion-item">
      <div
        data-testid="accordion-title"
        className="accordion-title"
        onClick={() => setIsActive(!isActive)}
      >
        <div>
          {icon && (
            <FontAwesomeIcon className="accordion-icon" icon={icon} size="xl" />
          )}
          {title}
        </div>
        <div>
          {isActive ? (
            <FontAwesomeIcon
              data-testid="minus-icon"
              className="alert-panel-error-icon fa-1x"
              icon={faMinus}
            />
          ) : (
            <FontAwesomeIcon
              data-testid="plus-icon"
              className="alert-panel-error-icon fa-1x"
              icon={faPlus}
            />
          )}
        </div>
      </div>
      {isActive && <div className="accordion-content">{content}</div>}
    </div>
  );
}

export default Accordion;
