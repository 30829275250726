/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useAppSelector } from '../../../hooks';
import { HistoricVesselPoint } from '../historic-vessel-point.model';
import HistoryResultCard from './history-result-card/history-result-card';

function HistoryResultsList() {
  const { historicVesselPoints, formValues } = useAppSelector(
    (state) => state.historyPanel
  );

  const identifiersList = formValues.identifiers.split(' ');

  return (
    <div className="history-panel-results" data-testid="history-panel-results">
      {historicVesselPoints.length ? (
        identifiersList.map((identifier: string) => {
          // TODO: Check for IMO once we have it in API Response
          const details = historicVesselPoints.find(
            (point: HistoricVesselPoint) => point.mmsi === Number(identifier)
          );

          // TODO: Replace collection_type with name once we have it in API Response
          // TODO: Show "no result found for vessel X" ?
          return details ? (
            <HistoryResultCard
              mmsi={details.mmsi}
              key={details.vessel_id}
              vesselId={details.vessel_id}
              coordinates={[details.long, details.lat]}
            />
          ) : null;
        })
      ) : (
        <span>No Vessel results. Please try new query parameters.</span>
      )}
    </div>
  );
}

export default HistoryResultsList;
