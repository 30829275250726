import './open-sea-maps.scss';

function OpenSeaMapPanel() {
  return (
    <div className="osm-panel-container">
      <h3>Charting</h3>
      <p> Open Sea Map </p>
    </div>
  );
}

export default OpenSeaMapPanel;
