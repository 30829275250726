import { Vessel } from '../../models/vessel.model';

export enum EAlertTypes {
  AISO = 'AIS_OFF',
  ETW = 'ETW',
}

export interface AlertingVessel extends Vessel {
  name: string;
  positionTimestamp: string;
  positionDelta: string;
  alertType: string;
  imo: number;
}

export interface AlertingVessels {
  alertType: string;
  alertTime: string;
  expiryTime: string;
  mmsi: string;
  imo: string;
  lat: string;
  long: string;
  course: number;
  heading: number;
  name: string;
}

export interface LocationData {
  name: string;
  imo: string;
  flag: string;
  mmsi: string;
  timestamp: string;
  lat: string;
  long: string;
  course: number;
  heading: number;
}

export interface AlertDataResponse {
  alert_type: string;
  alert_time: string;
  expiry_time: string;
  current_mmsi: string;
  vessel_name: string;
  vessel_type: string;
  imo: string;
}

export interface AlertData {
  alertType: string;
  alertTime: string;
  expiryTime: string;
  mmsi: string;
  name: string;
  type: string;
  imo: string;
}
