namespace PopupHelpers {
  interface InfoCard {
    label: string;
    value: string | Number | undefined;
  }

  export const createInfoCard = (
    popup: mapboxgl.Popup,
    info: InfoCard[],
    header?: string
  ) => {
    const body = info
      .map((section) => {
        const { label, value } = section;
        return `<div>${label && `<b>${label}: </b>`}${
          value || 'Unavailable'
        }</div>`;
      })
      .join('');

    const html = `${
      header ? `<div class="mapboxgl-popup-content-header">${header}</div>` : ''
    }${body}`;

    popup.setHTML(html);
  };
}

export default PopupHelpers;
