const AWS_REGION = process.env.REACT_APP_AWS_REGION;
const AWS_USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID;
const AWS_USER_POOL_WEBCLIENT_ID =
  process.env.REACT_APP_AWS_USER_POOL_WEBCLIENT_ID;

const awsExports = {
  aws_project_region: AWS_REGION,
  aws_cognito_region: AWS_REGION,
  aws_user_pools_id: AWS_USER_POOL_ID,
  aws_user_pools_web_client_id: AWS_USER_POOL_WEBCLIENT_ID,
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL', 'NAME'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};

export default awsExports;
