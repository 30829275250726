import MapLayerIcon from '../../map/map-layer-manager/map-layer-icon';
import MapLayerManager from '../../map/map-layer-manager/map-layer-manager.utils';
import MapLayerPaint from '../../map/map-layer-manager/map-layer-paint';
import MapLayerVisibility from '../../map/map-layer-manager/map-layer-visibility.enum';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import addSimpleGeoJsonSource from '../../map/map-layer-manager/vessel-utils/add-simple-geojson-source';
import addVesselsLayer from '../../map/map-layer-manager/vessel-utils/add-vessels-layer';
import AISDataGapsController from './history-ais-data-gaps/ais-data-gaps-controller.utils';
import AISVesselsController from '../../map/map-layer-manager/vessel-utils/ais-vessel-controller.utils';
import getUniqueHistoricVessels from '../../map/map-layer-manager/vessel-utils/get-unique-historic-vessels';
import setVesselFeatures from '../../map/map-layer-manager/vessel-utils/set-vessel-features';
import setVesselJourney from '../../map/map-layer-manager/vessel-utils/set-vessel-journey';
import MapHelpers from '../../map/map.utils';
import store from '../../store';
import { HistoricVesselPoint } from './historic-vessel-point.model';
import GeoHelper from '../../utils/geo-helpers.utils';
import {
  hideAISDataGapPointPopups,
  toPopup,
} from './history-ais-data-gaps/ais-data-gaps-popup';

namespace VesselHistoryController {
  // TODO: add time argument - could be used for time slider
  export const getHistoricVesselsSnapshot = (points: HistoricVesselPoint[]) => {
    const uniqueVessels = getUniqueHistoricVessels(points);
    const historicVessels: HistoricVesselPoint[] = uniqueVessels
      .map((vessel) =>
        // find first point in vessel journey
        points.find((point) => point.vessel_id === vessel)
      )
      .filter((item): item is HistoricVesselPoint => !!item);

    return historicVessels;
  };

  export const layerList = {
    JOURNEYS: {
      addLayer: (layerId: string, data: HistoricVesselPoint[]) => {
        const map = MapHelpers.getMapInstance();

        addSimpleGeoJsonSource(map, layerId);
        map.addLayer({
          id: layerId,
          type: 'line',
          source: layerId,
          layout: {
            visibility: MapLayerVisibility.VISIBLE,
          },
          paint: MapLayerPaint.VESSEL_HISTORY_JOURNEY,
          metadata: MapLayerManager.groupLayerMetaData(
            MapLayer.VESSEL_HISTORY_JOURNEYS
          ),
        });

        setVesselJourney(data, layerId);
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapLayer.VESSEL_HISTORY_JOURNEYS}`,
      groupLayer: MapLayer.VESSEL_HISTORY_JOURNEYS,
    },
    AIS_POSITIONS: {
      addLayer: (layerId: string, data: HistoricVesselPoint[]) => {
        const map = MapHelpers.getMapInstance();

        addSimpleGeoJsonSource(map, layerId);
        map.addLayer({
          id: layerId,
          type: 'symbol',
          source: layerId,
          minzoom: 7,
          layout: {
            visibility: MapLayerVisibility.VISIBLE,
            'icon-image': MapLayerIcon.HISTORIC_POSITION,
            'icon-rotation-alignment': 'map',
            'icon-rotate': ['number', ['get', 'course'], 360],
            'icon-size': [
              'interpolate',
              ['linear'],
              ['zoom'],
              // zoom is 5 (or less) -> icon will be half size
              5,
              0.5,
              // zoom is 10 (or greater) -> icon will be full size
              10,
              1,
            ],
          },
          metadata: MapLayerManager.groupLayerMetaData(
            MapLayer.VESSEL_HISTORY_AIS_POSITIONS
          ),
        });

        setVesselFeatures(layerId, data);

        map.on(
          'mouseenter',
          layerId,
          AISVesselsController.layerEvents.onMouseEnter
        );
        map.on(
          'mouseleave',
          layerId,
          AISVesselsController.layerEvents.onMouseLeave
        );
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapLayer.VESSEL_HISTORY_AIS_POSITIONS}`,
      groupLayer: MapLayer.VESSEL_HISTORY_AIS_POSITIONS,
    },
    AIS_HEATMAPS: {
      addLayer: (layerId: string, data: HistoricVesselPoint[]) => {
        const map = MapHelpers.getMapInstance();

        addSimpleGeoJsonSource(map, layerId);
        map.addLayer({
          id: layerId,
          type: 'heatmap',
          source: layerId,
          paint: MapLayerPaint.DEFAULT_HEATMAP,
          layout: {
            visibility: MapLayerVisibility.NOT_VISIBLE,
          },
          metadata: MapLayerManager.groupLayerMetaData(
            MapLayer.VESSEL_HISTORY_HEATMAPS
          ),
        });

        setVesselFeatures(layerId, data);
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapLayer.VESSEL_HISTORY_HEATMAPS}`,
      groupLayer: MapLayer.VESSEL_HISTORY_HEATMAPS,
    },
    VESSELS: {
      addLayer: (layerId: string, data: HistoricVesselPoint[]) => {
        const map = MapHelpers.getMapInstance();

        addVesselsLayer(
          map,
          layerId,
          MapLayerManager.groupLayerMetaData(MapLayer.VESSEL_HISTORY_VESSELS)
        );
        const snapshot =
          VesselHistoryController.getHistoricVesselsSnapshot(data);

        setVesselFeatures(layerId, snapshot);
      },
      getLayerKey: (vesselId: string) =>
        `${vesselId}_${MapLayer.VESSEL_HISTORY_VESSELS}`,
      groupLayer: MapLayer.VESSEL_HISTORY_VESSELS,
    },
  };

  // list of layers that need to be drawn and displayed dynamically for Vessel History
  export const getLayerListSources = (
    historicVesselPoints: HistoricVesselPoint[]
  ) => {
    const uniqueVessels = getUniqueHistoricVessels(historicVesselPoints);

    return {
      AIS_POSITIONS: uniqueVessels.map((vesselId) =>
        layerList.AIS_POSITIONS.getLayerKey(vesselId)
      ),
      AIS_HEATMAPS: uniqueVessels.map((vesselId) =>
        layerList.AIS_HEATMAPS.getLayerKey(vesselId)
      ),
      JOURNEYS: uniqueVessels.map((vesselId) =>
        layerList.JOURNEYS.getLayerKey(vesselId)
      ),
      VESSELS: uniqueVessels.map((vesselId) =>
        layerList.VESSELS.getLayerKey(vesselId)
      ),
      AIS_DATA_GAPS: uniqueVessels.length
        ? [MapLayer.VESSEL_HISTORY_AIS_DATA_GAPS]
        : [],
    };
  };

  export const clearAllHistoryLayers = () => {
    const map = MapHelpers.getMapInstance();

    // delete all dynamic layers by their group layer id.
    Object.values(layerList).forEach((item) => {
      const existingLayers = MapLayerManager.findLayersByGroupLayerId(
        map,
        item.groupLayer
      );
      existingLayers.forEach((layer) =>
        MapLayerManager.destroyLayer(map, layer.id)
      );
    });

    AISDataGapsController.clearSelectedFeature();
    hideAISDataGapPointPopups();
  };

  export const createAISDataGapsLayer = () => {
    const map = MapHelpers.getMapInstance();
    const AIS_DATA_GAPS_LAYER_ID = MapLayer.VESSEL_HISTORY_AIS_DATA_GAPS;

    addSimpleGeoJsonSource(map, AIS_DATA_GAPS_LAYER_ID);

    map.addLayer({
      id: AIS_DATA_GAPS_LAYER_ID,
      type: 'line',
      source: AIS_DATA_GAPS_LAYER_ID,
      layout: {
        visibility: MapLayerVisibility.VISIBLE,
      },
      paint: MapLayerPaint.HIGHLIGHTED_LINES,
      metadata: MapLayerManager.groupLayerMetaData(
        MapLayer.VESSEL_HISTORY_AIS_POSITIONS
      ),
    });

    map.on('mouseenter', AIS_DATA_GAPS_LAYER_ID, (e) =>
      AISDataGapsController.layerEvents.onMouseEnter(e)
    );
    map.on('mouseleave', AIS_DATA_GAPS_LAYER_ID, (e) =>
      AISDataGapsController.layerEvents.onMouseLeave(e)
    );

    map.on('click', AIS_DATA_GAPS_LAYER_ID, (e) =>
      AISDataGapsController.layerEvents.onClick(e)
    );

    MapHelpers.onLayerVisibilityChange(
      AIS_DATA_GAPS_LAYER_ID,
      AISDataGapsController.onAISDataGapsLayerVisibilityChange
    );

    // register the event for de-selecting a feature only when popup is open
    // this should prevent duplicates/unnecessary popup events being called
    toPopup.on('open', () =>
      toPopup.once('close', AISDataGapsController.addPopupEventListeners)
    );
  };

  export const init = () => {
    const { historicVesselPoints } = store.getState().historyPanel;
    const uniqueVessels = getUniqueHistoricVessels(historicVesselPoints);

    // prevent duplicate layers being drawn
    clearAllHistoryLayers();

    // build layers from layer list dynamically
    uniqueVessels.forEach((vesselId: string) => {
      const filteredPoints = historicVesselPoints.filter(
        (point) => point.vessel_id === vesselId
      );

      Object.values(layerList).forEach((item) => {
        const { addLayer, getLayerKey } = item;
        addLayer(getLayerKey(vesselId), filteredPoints);
      });
    });

    createAISDataGapsLayer();
  };

  export const onVesselHistoryDraw = () => {
    const { historicVesselPoints } = store.getState().historyPanel;
    const geojson = GeoHelper.createGeoJSON(
      GeoHelper.vesselsToGeoJSONFeatures(historicVesselPoints)
    );

    MapHelpers.zoomToFeatureCollection(geojson);
  };
}

export default VesselHistoryController;
