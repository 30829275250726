import { withAuthenticator as withAuthenticatorAmplify } from '@aws-amplify/ui-react';
import React from 'react';
import {
  WithAuthenticatorOptions,
  WithAuthenticatorProps,
} from '@aws-amplify/ui-react/dist/types/components/Authenticator/withAuthenticator';

const AWS_MOCK_COGNITO = process.env.REACT_APP_AWS_MOCK_COGNITO;

function withMockedAuthenticator<Props extends object>(
  Comp: React.ComponentType<Props>
) {
  return Comp;
}
function withAuthenticator<Props = {}>(
  Component: React.ComponentType<Props & WithAuthenticatorProps>,
  options?: WithAuthenticatorOptions
) {
  return AWS_MOCK_COGNITO
    ? withMockedAuthenticator(Component)
    : withAuthenticatorAmplify(Component, options);
}
export default withAuthenticator;
