import GeoHelper from '../../../utils/geo-helpers.utils';
import MapLayer from '../map-layer.enum';

function addSimpleGeoJsonSource(map: mapboxgl.Map, layer: MapLayer | string) {
  map.addSource(layer, {
    type: 'geojson',
    data: GeoHelper.createGeoJSON(),
    // create unique ids for each feature
    generateId: true,
  });
}

export default addSimpleGeoJsonSource;
