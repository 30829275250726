import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import './user-button.scss';
import UserMenu from './user-menu/user-menu';

interface UserButtonProps {}

interface UserButtonState {
  menuOpen: boolean;
}

class UserButton extends React.Component<UserButtonProps, UserButtonState> {
  constructor(props: UserButtonProps) {
    super(props);
    this.state = {
      menuOpen: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  }

  render() {
    const { menuOpen } = this.state;
    return (
      <div className="user-menu-container">
        <button
          className={`user-button ${
            menuOpen ? 'user-menu-open' : 'user-menu-closed'
          }`}
          type="button"
          title="Open user menu"
          onClick={this.handleClick}
        >
          <FontAwesomeIcon className="user-button-icon fa-3x" icon={faUser} />
        </button>
        {menuOpen && <UserMenu />}
      </div>
    );
  }
}

export default UserButton;
