import axios from 'axios';
import { useEffect, useState } from 'react';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import SimpleSearchBar from '../../common-components/simple-search-bar/simple-search-bar';
import SimpleVesselTable from '../../common-components/simple-vessel-table/simple-vessel-table';
import { useAppDispatch, useAppSelector } from '../../hooks';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import setVesselFeatures from '../../map/map-layer-manager/vessel-utils/set-vessel-features';

import './myFleet.scss';
import { setError, setLoading, setMyFleet } from './myFleet.slice';
import { MyFleetVessel } from './myFleetVessels.model';

const fetchData = async (dispatch: any) => {
  const GEONIUS_BACKEND_URL_STEM = process.env.REACT_APP_GEONIUS_BACKEND_URL;
  const MARITIME_HOT_URL_STEM = process.env.REACT_APP_MARITIME_HOT_URL;

  let companyMmsis: string[] = [];
  await axios
    .get(
      `${GEONIUS_BACKEND_URL_STEM}/vessels?company_id='0b474cdb-3a8c-45b3-91c1-5bdfef855051'`,
      {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      }
    )
    .then((response) => {
      companyMmsis = response.data;
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch((_error) => {
      dispatch(setError());
    });

  await axios
    .post(`${MARITIME_HOT_URL_STEM}/location_messages/last_known_locations`, {
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        identifiers: {
          mmsis: companyMmsis,
        },
      },
    })
    .then((response) => {
      const myFleetResponse = response.data;
      const myFleetArray: MyFleetVessel[] = [];
      myFleetResponse.forEach((vessel: MyFleetVessel) =>
        myFleetArray.push({
          name: vessel.name,
          imo: vessel.imo,
          mmsi: vessel.mmsi,
          flag: vessel.flag,
          heading: vessel.heading,
          lat: vessel.lat,
          long: vessel.long,
          course: vessel.course,
          timestamp: vessel.timestamp,
        })
      );

      dispatch(setMyFleet(myFleetArray));
    })
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .catch((_error) => {
      dispatch(setError());
    });
};

function mapToTableObjects(myFleet: MyFleetVessel[] | null) {
  const myFleetDetailsToDisplay: Object[] = [];
  if (myFleet) {
    myFleet.forEach((vessel: MyFleetVessel) => {
      myFleetDetailsToDisplay.push(
        // This extracts the specific properties from vessel, and puts them into a new object, to be pushed onto the array.
        // This array is used to display in the table
        (({ name, mmsi, flag }) => ({ name, mmsi, flag }))(vessel)
      );
    });
  }
  return myFleetDetailsToDisplay;
}

function MyFleetPanel() {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.myFleet.loading);
  const hasError = useAppSelector((state) => state.myFleet.error);
  const myFleet = useAppSelector((state) => state.myFleet.myFleet);
  const { mapInitialised } = useAppSelector((state) => state.map);

  const [filterValue, setFilterValue] = useState('');
  const MYF_TABLE_HEADERS = ['Name', 'MMSI', 'Flag'];

  const handleSearchChange = (value: string) => {
    setFilterValue(value);
  };

  const myFleetDetailsToDisplay = mapToTableObjects(myFleet);

  useEffect(() => {
    if (mapInitialised && myFleet) {
      setVesselFeatures(MapLayer.MY_FLEET_VESSELS, myFleet);
    }
  }, [myFleet, mapInitialised]);

  useEffect(() => {
    if (!myFleet) {
      dispatch(setLoading());
    }
    fetchData(dispatch);
  }, []);

  return (
    <div className="myf-panel-container">
      <h3>My Fleet</h3>
      {loading && <LoadingPanel />}
      {!loading && hasError && (
        <ErrorPanel
          message="Unable to get My Fleet Data... Please try again later"
          messageColour="white"
        />
      )}
      {!loading && !hasError && myFleet && (
        <div data-testid="my-fleet-panel">
          <SimpleSearchBar
            handleChange={handleSearchChange}
            filterValue={filterValue}
          />
          <div className="my-fleet-table">
            <SimpleVesselTable
              columnHeaders={MYF_TABLE_HEADERS}
              data={myFleetDetailsToDisplay}
              dataFilter={(vessel: MyFleetVessel) =>
                vessel.mmsi.toString().includes(filterValue.toLowerCase()) ||
                vessel.name.toString().includes(filterValue.toLowerCase()) ||
                vessel.flag.toString().includes(filterValue.toLowerCase())
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default MyFleetPanel;
