/* eslint-disable jsx-a11y/click-events-have-key-events */

import { useEffect, useState } from 'react';
import { useAppSelector } from '../../hooks';
import MapHelpers from '../../map/map.utils';
import ItemToggle, { ItemToggleProps } from '../item-toggle/item-toggle';

interface LayerToggleProps extends ItemToggleProps {
  // Map Layer Source ID
  sources: string[];
  label?: string;
  disabled?: boolean;
}

function LayerToggle(props: LayerToggleProps) {
  const {
    label,
    sources,
    disabled,
    toggledIcon,
    untoggledIcon,
    untoggledClassName,
  } = props;
  const { mapInitialised } = useAppSelector((state) => state.map);

  const someLayersToggled = () =>
    sources.some((source) => MapHelpers.isLayerVisible(source));

  const [toggled, setToggled] = useState(
    !mapInitialised || someLayersToggled()
  );
  const isDisabled = !mapInitialised || disabled;

  useEffect(() => {
    if (!mapInitialised) {
      return;
    }

    setToggled(someLayersToggled());

    sources.forEach((source) => {
      MapHelpers.onLayerVisibilityChange(source, () =>
        setToggled(someLayersToggled())
      );
    });

    // eslint-disable-next-line consistent-return
    return () => {
      sources.forEach((source) =>
        MapHelpers.removeLayerVisibilityEvent(source, () => {})
      );
    };
  }, [sources]);

  return (
    <ItemToggle
      defaultChecked={isDisabled ? false : toggled}
      onChange={(visible: boolean) =>
        sources.forEach((source) =>
          MapHelpers.setLayerVisibility(source, visible)
        )
      }
      toggledTooltip={label && `Show ${label}`}
      untoggledTooltip={label && `Hide ${label}`}
      disabled={isDisabled}
      toggledIcon={toggledIcon}
      untoggledIcon={untoggledIcon}
      untoggledClassName={untoggledClassName}
    />
  );
}

LayerToggle.defaultProps = {
  label: '',
  disabled: false,
};

export default LayerToggle;
