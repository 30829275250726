/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import Switch from 'react-switch';
import { EAlertTypes } from '../../maritime-menu-options/alert-panel/alertingVessels.model';
import './toggle-switch.scss';

interface ToggleSwitchProps {
  label: string;
  alertType: EAlertTypes;
  onToggle: (alertType: EAlertTypes, checked: boolean) => void;
}

function ToggleSwitch(props: ToggleSwitchProps) {
  const [checked, setChecked] = useState(true);

  const { label, onToggle, alertType } = props;
  return (
    <div className="toggle-switch-container">
      <div className="switch-container">
        <Switch
          data-testid={`toggle-switch-${alertType}`}
          checked={checked}
          onChange={() => {
            setChecked(!checked);
            onToggle(alertType, !checked);
          }}
          onColor="#1b5066"
          offColor="#383334"
          onHandleColor="#008bcc"
          handleDiameter={20}
          uncheckedIcon={false}
          checkedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
          height={10}
          width={40}
        />
      </div>
      <div className="toggle-switch-label">
        <span>{label}</span>
      </div>
    </div>
  );
}

export default ToggleSwitch;
