import MapLayer from '../map-layer.enum';
import addPlainVesselLayerToMap from './add-plain-vessel-layer';
import addSimpleGeoJsonSource from './add-simple-geojson-source';
import VesselsController from './vessel-controller.utils';

function addVesselsLayer(
  map: mapboxgl.Map,
  layer: MapLayer | string,
  metadata?: {}
) {
  // eslint-disable-next-line no-new
  new Promise((resolve) => {
    addSimpleGeoJsonSource(map, layer);
    addPlainVesselLayerToMap(map, layer, metadata);

    map.on('mouseenter', layer, (e) =>
      VesselsController.layerEvents.onMouseEnter(e)
    );
    map.on('mouseleave', layer, (e) =>
      VesselsController.layerEvents.onMouseLeave(e)
    );
    map.on('click', layer, (e) => {
      VesselsController.layerEvents.onClick(e, layer);
    });

    resolve(map);
  });
}

export default addVesselsLayer;
