import './feedback-bar.scss';

function FeedbackBar() {
  return (
    <div className="feedback-bar-container">
      <span
        data-testid="feedback-bar-container-message"
        className="feedback-bar-container-message"
      >
        We would welcome any feedback for the Geonius Platform!
      </span>
    </div>
  );
}

export default FeedbackBar;
