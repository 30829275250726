const OpenSeaMapsLabels = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {
        name: 'These charts are not for navigation',
        stroke: '#ffffff',
        'stroke-width': 2,
        'stroke-opacity': 1,
      },
      geometry: {
        coordinates: [
          [0, 79.5],
          [1, 79.5],
        ],
        type: 'LineString',
      },
    },
    {
      type: 'Feature',
      properties: {
        name: 'These charts are not for navigation',
        stroke: '#ffffff',
        'stroke-width': 2,
        'stroke-opacity': 1,
      },
      geometry: {
        coordinates: [
          [0, -79.5],
          [1, -79.5],
        ],
        type: 'LineString',
      },
    },
  ],
};

export default OpenSeaMapsLabels;
