/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_ALERTING_VESSELS_STATE = {
  loading: false,
  error: false,
  alertingVessels: null,
};

export const AlertingVesselsSlice = createSlice({
  name: 'alertingVessels',
  initialState: INITIAL_ALERTING_VESSELS_STATE,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setAlertingVessels: (state, action) => {
      state.alertingVessels = action.payload;
      state.loading = false;
      state.error = false;
    },
  },
});

// Action creators are generated for each case reducer function

export const { setLoading, setError, setAlertingVessels } =
  AlertingVesselsSlice.actions;

export default AlertingVesselsSlice.reducer;
