/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import LayerToggle from '../../../common-components/layer-toggle/layer-toggle';

import './side-menu-item.scss';

interface SideMenuItemProps {
  itemName: string;
  iconName: IconDefinition;
  menuOpen: boolean;
  selected: boolean;
  onClick: () => void;
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  layerSources?: string[];
  // eslint-disable-next-line react/no-unused-prop-types, react/require-default-props
  layerToggleDisabled?: boolean;
}

interface SideMenuItemState {}

class SideMenuItem extends React.Component<
  SideMenuItemProps,
  SideMenuItemState
> {
  render() {
    const {
      itemName,
      iconName,
      menuOpen,
      onClick,
      selected,
      layerSources,
      layerToggleDisabled,
    } = this.props;

    return (
      <div
        className={`side-menu-item ${selected && 'side-menu-item-selected'}`}
      >
        <div
          data-testid="side-menu-item-label"
          className="side-menu-item-label"
          onClick={onClick}
        >
          <FontAwesomeIcon className="side-menu-icon fa-xl" icon={iconName} />
          {menuOpen && <span className="side-menu-item-name">{itemName}</span>}
        </div>

        {menuOpen && layerSources && (
          <div
            className="side-menu-item-toggle"
            data-testid="side-menu-item-toggle"
          >
            <LayerToggle
              sources={layerSources}
              label={itemName}
              disabled={layerToggleDisabled}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SideMenuItem;
