import { Auth } from '@aws-amplify/auth';
import { faFaceLaugh } from '@fortawesome/free-regular-svg-icons';
import {
  faPeopleRoof,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import './user-menu.scss';

const clickSignOutBtn = () => {
  Auth.signOut();
};

function UserMenu() {
  return (
    <div className="user-menu">
      <ul>
        <li>
          <Link to="/admin/companies">
            <div className="user-menu-list-element">
              <FontAwesomeIcon
                className="user-menu-list-element-icon fa-1x"
                icon={faPeopleRoof}
              />
              Admin Options
            </div>
          </Link>
        </li>
        <hr />
        <li>
          <div className="user-menu-list-element">
            <FontAwesomeIcon
              className="user-menu-list-element-icon fa-1x"
              icon={faFaceLaugh}
            />
            User Preferences
          </div>
        </li>
        <hr />
        <li>
          <div
            className="user-menu-list-element"
            onClick={clickSignOutBtn}
            onKeyDown={clickSignOutBtn}
            role="presentation"
          >
            <FontAwesomeIcon
              className="user-menu-list-element-icon fa-1x"
              icon={faRightFromBracket}
            />
            Logout
          </div>
        </li>
      </ul>
    </div>
  );
}

export default UserMenu;
