/* eslint-disable @typescript-eslint/no-unused-vars */
// import 'react-tabs/style/react-tabs.css';
import {
  IVesselBuildHistory,
  IVesselBunker,
  IVesselCapacity,
  IVesselDesign,
  IVesselDimensions,
  IVesselPropulsion,
  IVesselRegistration,
  IVesselTypeAndTrading,
} from '../vessel-information.model';
import './vessel-information-card.scss';
import VesselInformationLabel from './vessel-information-label/vessel-information-label';

interface VesselInformationCardProps {
  labels: string[];
  information:
    | IVesselCapacity
    | IVesselDesign
    | IVesselDimensions
    | IVesselBuildHistory
    | IVesselPropulsion
    | IVesselRegistration
    | IVesselTypeAndTrading
    | IVesselBunker;
}

function VesselInformationCard(props: VesselInformationCardProps) {
  const { labels, information } = props;

  return (
    <div className="vessel-information-card">
      {Object.entries(information).map(([_key, value], index) => (
        <VesselInformationLabel
          key={labels[index] + value}
          dataStyle="black"
          title={labels[index]}
          data={value}
        />
      ))}
    </div>
  );
}

export default VesselInformationCard;
