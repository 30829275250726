import AllVessels from './all-vessels-panel/all-vessels-panel';
import './manage-vessels.scss';

function ManageVessels() {
  return (
    <div className="vessel-management-container">
      <h1>Vessel management</h1>
      <div className="vessel-management-lists">
        <div className="all-vessel-lists-panel">company lists</div>
        <div className="all-vessels-panel">
          <AllVessels />
        </div>
      </div>
    </div>
  );
}

export default ManageVessels;
