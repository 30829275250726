/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import mapboxgl from 'mapbox-gl';

export const INITIAL_MAP_STATE = {
  lon: -2.58,
  lat: 51.45,
  zoom: 6,
  map: {} as mapboxgl.Map,
  mapInitialised: false,
  style: 'mapbox://styles/geollectryan/cl8ikedfe007x14mv9jns580g',
};

export const MapSlice = createSlice({
  name: 'map',
  initialState: INITIAL_MAP_STATE,
  reducers: {
    setLatLon: (state, action) => {
      state.lat = action.payload.lat;
      state.lon = action.payload.lon;
    },
    setZoom: (state, action) => {
      state.zoom = action.payload;
    },
    setStyle: (state, action) => {
      state.style = action.payload;
    },
    setMap: (state, action: PayloadAction<mapboxgl.Map>) => {
      state.map = action.payload;
    },
    setMapInitialised: (state, action) => {
      state.mapInitialised = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const { setLatLon, setZoom, setStyle, setMap, setMapInitialised } =
  MapSlice.actions;

export default MapSlice.reducer;
