/* eslint-disable jsx-a11y/label-has-associated-control */
import axios from 'axios';
import { Form, Formik } from 'formik';
import ErrorPanel from '../../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../../common-components/loading-panel/loading-panel';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import HistoryPanelSampleRate from '../history-panel-sample-rate';
import {
  HistoryPanelViewState,
  setError,
  setFormValues,
  setHistoricVesselPoints,
  setLoading,
  setViewState,
} from '../history-panel.slice';
import VesselHistoryController from '../vessel-history-controller.utils';
import { HistoryFormValues, validateForm } from './history-form-validators';
import './history-form.scss';

// import VesselHistoryLarge from '../../../mocks/stubs/vessel-history-large.mock';
// import { HistoricVesselPoint } from '../historic-vessel-point.model';
import AISDataGapsController from '../history-ais-data-gaps/ais-data-gaps-controller.utils';

export const ERROR_MESSAGE =
  'Unable to fetch Vessel History. Please try again later...';

function HistoryPanelForm() {
  const MARITIME_COLD_URL_STEM = process.env.REACT_APP_MARITIME_COLD_URL;
  const dispatch = useAppDispatch();
  const { loading, error, formValues } = useAppSelector(
    (state) => state.historyPanel
  );

  const FORMATTED_TODAY = new Date().toISOString().split('T')[0];

  // TODO: Remove when we have Vessel History API Working.
  // USE THIS FOR TESTING
  // MMSIs used for testing: 201006210 201011000 201100093 725000613 725000625

  // const onSubmit = (values: HistoryFormValues) => {
  //   console.log(values, MARITIME_COLD_URL_STEM);
  //   const points = VesselHistoryLarge as unknown as HistoricVesselPoint[];

  //   dispatch(setError(false));
  //   dispatch(setFormValues(values));
  //   dispatch(setHistoricVesselPoints(points));

  //   VesselHistoryController.init();
  //   VesselHistoryController.onVesselHistoryDraw();
  //   AISDataGapsController.resetToDefaults();
  //   dispatch(setViewState(HistoryPanelViewState.RESULTS));
  // };

  const onSubmit = (values: HistoryFormValues) => {
    const queryParams = {
      'start-date': values.fromDate,
      'end-date': values.toDate,
      'sample-rate': values.sampleRate,
      mmsis: values.identifiers.split(' ').join(','),
      // move to helpers ^^
      // TODO: add helpers for filtering MMSIs and IMOs from Vessel Identifiers
    };

    const URL_PARAMS = new URLSearchParams(queryParams).toString();

    dispatch(setLoading(true));
    axios
      .get(`${MARITIME_COLD_URL_STEM}/location_messages?${URL_PARAMS}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        const points = response.data.data;

        dispatch(setError(false));
        dispatch(setFormValues(values));
        dispatch(setHistoricVesselPoints(points));

        VesselHistoryController.init();
        VesselHistoryController.onVesselHistoryDraw();

        AISDataGapsController.resetToDefaults();

        dispatch(setViewState(HistoryPanelViewState.RESULTS));
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_error) => {
        dispatch(setError(true));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };

  return (
    <div className="history-panel-filters" data-testid="history-panel-filters">
      <h4>Filters</h4>
      <Formik
        initialValues={{
          fromDate: formValues.fromDate,
          toDate: formValues.toDate,
          sampleRate: formValues.sampleRate,
          identifiers: formValues.identifiers,
        }}
        validate={validateForm}
        onSubmit={onSubmit}
      >
        {({ handleChange, errors, touched, values }) => (
          <Form>
            <div className="history-panel-date-filters">
              {errors.fromDate && touched.fromDate ? (
                <div
                  data-testid="from-date-error-message"
                  className="error-messages"
                >
                  {errors.fromDate}
                </div>
              ) : null}
              <div
                data-testid="from-date"
                className={`form-input-chip ${
                  errors.fromDate && touched.fromDate ? 'error-chip' : ''
                }`}
              >
                <label className="history-panel-label" htmlFor="from">
                  From:
                </label>
                <input
                  data-testid="from-date-input"
                  type="date"
                  id="from"
                  min="2018-12-08"
                  max={FORMATTED_TODAY}
                  onChange={handleChange}
                  name="fromDate"
                  defaultValue={values.fromDate}
                />
              </div>

              {errors.toDate && touched.toDate ? (
                <div
                  data-testid="to-date-error-message"
                  className="error-messages"
                >
                  {errors.toDate}
                </div>
              ) : null}
              <div
                data-testid="to-date"
                className={`form-input-chip ${
                  errors.toDate ? 'error-chip' : ''
                }`}
              >
                <label className="history-panel-label" htmlFor="to">
                  To:
                </label>
                <input
                  data-testid="to-date-input"
                  type="date"
                  id="to"
                  min="2018-12-08"
                  max={FORMATTED_TODAY}
                  onChange={handleChange}
                  name="toDate"
                  defaultValue={values.toDate}
                />
              </div>
            </div>
            <div className="form-input-chip" data-testid="sample-rate">
              <label className="history-panel-label" htmlFor="sample-rate">
                Rate:
              </label>

              <select
                name="sampleRate"
                onChange={handleChange}
                id="sample-rate"
                title="Rate at which data will be downsampled"
                defaultValue={values.sampleRate}
              >
                <option value={HistoryPanelSampleRate.TEN_MINUTES}>
                  Every 10 minutes
                </option>
                <option value={HistoryPanelSampleRate.ONE_HOUR}>Hourly</option>
                <option value={HistoryPanelSampleRate.ONE_DAY}>Daily</option>
              </select>
            </div>
            <h4>Vessel Identifiers</h4>
            {errors.identifiers && touched.identifiers ? (
              <div
                data-testid="identifiers-error-messages"
                className="error-messages"
              >
                {errors.identifiers}
              </div>
            ) : null}
            <div
              className="history-panel-identifiers"
              data-testid="identifier-textarea"
            >
              <span>Enter white-space seperated IMOs & MMSIs here:</span>
              <textarea
                className={`${
                  errors.identifiers && touched.identifiers ? 'error-chip' : ''
                }`}
                id="identifiers"
                name="identifiers"
                rows={5}
                onChange={handleChange}
                defaultValue={values.identifiers}
              />
            </div>

            {loading && <LoadingPanel />}
            {error && !loading && (
              <ErrorPanel message={ERROR_MESSAGE} messageColour="white" />
            )}

            <div className="history-panel-container-buttons">
              <button
                data-testid="history-panel-get-button"
                className="history-panel-get-button"
                type="submit"
                title="Get History"
                disabled={loading}
              >
                Get History
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default HistoryPanelForm;
