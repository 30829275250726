import { HistoricVesselPoint } from '../../../maritime-menu-options/history-panel/historic-vessel-point.model';
import GeoHelper from '../../../utils/geo-helpers.utils';
import getUniqueVesselsFromPoints from './get-unique-historic-vessels';

function createVesselJourneyFeatures(points: HistoricVesselPoint[]) {
  const uniqueVessels = getUniqueVesselsFromPoints(points);
  const journeyFeatures = uniqueVessels.map((vessel) => {
    const vesselGroup = points.filter((point) => point.vessel_id === vessel);
    const vesselGroupCoordinates = vesselGroup.map((v) => [v.long, v.lat]);
    return GeoHelper.createFeatureLineString(vesselGroupCoordinates, {
      vessel_id: vessel,
    });
  });

  return journeyFeatures;
}

export default createVesselJourneyFeatures;
