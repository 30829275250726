/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useAppSelector } from '../../hooks';
import HistoryForm from './history-form/history-form';
import './history-panel.scss';
import { HistoryPanelViewState } from './history-panel.slice';
import HistoryResultsView from './history-results/history-results-view';

function HistoryPanel() {
  const { viewState } = useAppSelector((state) => state.historyPanel);

  return (
    <div className="history-panel-container">
      <h3>Vessel History</h3>
      {viewState === HistoryPanelViewState.SEARCH && <HistoryForm />}
      {viewState === HistoryPanelViewState.RESULTS && <HistoryResultsView />}
    </div>
  );
}

export default HistoryPanel;
