import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './error-panel.scss';

interface ErrorPanelProps {
  message: string;
  messageColour: string;
}

function ErrorPanel(props: ErrorPanelProps) {
  const { message, messageColour } = props;
  return (
    <div data-testid="error-panel" className="error-panel">
      <ul>
        <li>
          <FontAwesomeIcon
            className="error-panel-icon fa-5x"
            icon={faTriangleExclamation}
          />
        </li>
        <li>
          <span
            data-testid="error-panel-message"
            style={{ color: messageColour }}
          >
            {message}
          </span>
        </li>
      </ul>
    </div>
  );
}

export default ErrorPanel;
