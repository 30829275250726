function addMapImage(
  imageImport: string,
  imageName: string,
  map: mapboxgl.Map
) {
  map.loadImage(imageImport, (error, image) => {
    if (error) {
      throw error;
    }
    map.addImage(imageName, image as HTMLImageElement);
  });
}

export default addMapImage;
