/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { GeoJSONSource } from 'mapbox-gl';
import { useEffect, useRef } from 'react';
import IconButton from '../../../common-components/icon-button/icon-button';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import MapLayer from '../../../map/map-layer-manager/map-layer.enum';
import { hideAISDataGapPointPopups } from './ais-data-gaps-popup';
import { setAISDataGapLength } from '../history-panel.slice';
import './history-ais-data-gaps.scss';
import AISDataGapsController from './ais-data-gaps-controller.utils';
import HistoryAISDataGapResultCard from './history-ais-data-gap-result-card';

function HistoryAISDataGaps() {
  const GAP_INTERVAL = 1;

  const { aisDataGapLength, selectedAISDataGapFeature } = useAppSelector(
    (state) => state.historyPanel
  );
  const { mapInitialised, map } = useAppSelector((state) => state.map);
  const dispatch = useAppDispatch();
  const resultsContainer = useRef<HTMLInputElement>(null);
  const timeGapData = AISDataGapsController.getFilteredAISDataGapFeatures();

  const handleClearSelection = () => {
    AISDataGapsController.clearSelectedFeature();
    hideAISDataGapPointPopups();
  };

  const handleAISDataGapLengthChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setAISDataGapLength(Number(e.target.value)));
    handleClearSelection();
  };

  useEffect(() => {
    if (mapInitialised) {
      (
        map.getSource(MapLayer.VESSEL_HISTORY_AIS_DATA_GAPS) as GeoJSONSource
      )?.setData(timeGapData);
    }
  }, [map, mapInitialised, aisDataGapLength]);

  useEffect(() => {
    if (resultsContainer.current === null || !selectedAISDataGapFeature) {
      return;
    }

    const result = resultsContainer.current.querySelector(
      `[data-resultid='${selectedAISDataGapFeature.properties?.id}']`
    );

    if (result) {
      result.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [selectedAISDataGapFeature]);

  return (
    <div className="history-ais-data-gaps">
      <div>Use the input box below to adjust the Gap Length:</div>
      <div className="history-ais-data-gaps-input-area">
        <input
          data-testid="history-ais-data-gaps-input"
          type="number"
          min={1}
          step={GAP_INTERVAL}
          defaultValue={aisDataGapLength}
          onChange={handleAISDataGapLengthChange}
        />
        <span className="gap-length-label">hour(s)</span>
      </div>
      {aisDataGapLength <= 0 || !timeGapData.features.length ? (
        <div
          className="history-ais-data-gaps-message"
          data-testid="history-ais-data-gaps-message"
        >
          No Data Gaps Found
        </div>
      ) : (
        <>
          <div className="history-ais-data-gaps-stats">
            Showing {timeGapData.features.length} Data Gap(s)
          </div>
          <IconButton
            tooltip="Clear Selection"
            classNames="history-ais-data-gap-clear-selection"
            label="Clear Selection"
            iconDef={faXmark}
            onClick={() => handleClearSelection()}
            disabled={selectedAISDataGapFeature === null}
          />
          <div className="history-ais-data-gap-results" ref={resultsContainer}>
            {
              // Sort Time Gap Data shortest - longest
              timeGapData.features.map((feature) => (
                <HistoryAISDataGapResultCard
                  key={feature.properties?.id}
                  feature={feature}
                  selected={
                    selectedAISDataGapFeature?.properties?.id ===
                    feature.properties?.id
                  }
                />
              ))
            }
          </div>
        </>
      )}
    </div>
  );
}

export default HistoryAISDataGaps;
