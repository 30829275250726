import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './search-bar.scss';

function SearchBar() {
  return (
    <div className="search-bar-container">
      <FontAwesomeIcon
        className="search-bar-conainer-icon fa-2x"
        icon={faMagnifyingGlass}
      />
      <input className="search-bar" placeholder="Search" />
    </div>
  );
}

export default SearchBar;
