/* eslint-disable jsx-a11y/aria-role */
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckboxTable from '../../../common-components/checkbox-table/checkbox-table';
import DeleteModal from '../../../common-components/delete-modal/delete-modal';
import ErrorPanel from '../../../common-components/error-components/error-panel/error-panel';
import IconButton from '../../../common-components/icon-button/icon-button';
import LoadingPanel from '../../../common-components/loading-panel/loading-panel';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
  setCompanies,
  setCompaniesError,
  setCompaniesLoading,
  setSelectedCompany,
} from '../admin.slice';
import CreateCompanyModal, {
  CreateCompanyFormValues,
} from './create-company-modal/create-company-modal';

import './manage-companies.scss';

function mapToTableObjects(companies: any[] | null) {
  let detailsToDisplay: Object[] = [];
  if (companies) {
    detailsToDisplay = companies.map((company) => ({
      id: company.company_id,
      company_name: company.company_name,
    }));
  }
  return detailsToDisplay;
}

function ManageCompanies() {
  const GEONIUS_BACKEND_URL_STEM = process.env.REACT_APP_GEONIUS_BACKEND_URL;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.admin.companiesLoading);
  const hasError = useAppSelector((state) => state.admin.companiesError);
  const companies: any = useAppSelector((state) => state.admin.companies);

  const detailsToDisplay = mapToTableObjects(companies);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false);

  const [deleteLoading, setShowDeleteLoading] = useState<boolean>(false);
  const [deleteError, setShowDeleteError] = useState<boolean>(false);
  const [deleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);

  const [createCompanyLoading, setShowCreateLoading] = useState<boolean>(false);
  const [createCompanyError, setShowCreateError] = useState<boolean>(false);
  const [createCompanySuccess, setShowCreateSuccess] = useState<boolean>(false);

  useEffect(() => {
    if (!companies) {
      dispatch(setCompaniesLoading());
      axios
        .get(`${GEONIUS_BACKEND_URL_STEM}/companies`, {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        })
        .then((response) => {
          dispatch(setCompanies(response.data));
        })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .catch((_error) => {
          dispatch(setCompaniesError());
        });
    }
  }, []);

  const onDeleteCompanies = () => {
    setShowDeleteLoading(true);
    axios
      .delete(`${GEONIUS_BACKEND_URL_STEM}/companies`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          company_ids: selectedIds,
        },
      })
      .then(() => {
        setShowDeleteLoading(false);
        setShowDeleteSuccess(true);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_error) => {
        setShowDeleteLoading(false);
        setShowDeleteError(true);
      });
  };

  const onCreateCompany = (values: CreateCompanyFormValues) => {
    const { companyName } = values;
    setShowCreateLoading(true);
    axios
      .post(`${GEONIUS_BACKEND_URL_STEM}/companies`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          company_name: companyName,
        },
      })
      .then(() => {
        setShowCreateLoading(false);
        setShowCreateSuccess(true);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_error) => {
        setShowCreateLoading(false);
        setShowCreateError(true);
      });
  };

  const toggleShowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
    if (!showDeleteModal) {
      setShowDeleteLoading(false);
      setShowDeleteError(false);
      setShowDeleteSuccess(false);
    }
  };

  const toggleShowCreateModal = () => {
    setShowCreateModal(!showCreateModal);
    if (!showCreateModal) {
      setShowCreateLoading(false);
      setShowCreateError(false);
      setShowCreateSuccess(false);
    }
  };

  const modifyselectedIds = (id: string) => {
    // if compnay is in the selected company list already then delete it
    if (selectedIds.some((selectedId) => selectedId === id)) {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter(
          (previouslySelectedId) => previouslySelectedId !== id
        )
      );
    } else {
      setSelectedIds((oldArray) => [...oldArray, id]);
    }
  };

  const toggleAllCompanies = () => {
    if (selectedIds.length < companies.length) {
      const allIds = companies.map((company: any) => company.company_id);
      setSelectedIds([...allIds]);
    } else {
      setSelectedIds([]);
    }
  };

  const getCompanyNameFromId = (id: string) =>
    companies.find((company: any) => company.company_id === id);

  const goToCompanyView = (id: string) => {
    dispatch(setSelectedCompany(getCompanyNameFromId(id)));
    navigate(
      `/admin/companies/${getCompanyNameFromId(id).company_name.replace(
        /\s+/g,
        ''
      )}`
    );
  };

  return (
    <div
      data-testid="company-management-container"
      className="company-management-container"
    >
      <div className="company-management">
        <h1>Company Management</h1>
        <div className="company-crud-buttons">
          <IconButton
            iconDef={faTrash}
            tooltip="Remove company"
            label="Remove Company"
            onClick={toggleShowDeleteModal}
            classNames="remove-company"
            disabled={selectedIds.length === 0}
          />
          <IconButton
            iconDef={faPlus}
            tooltip="Add company"
            label="Add Company"
            onClick={toggleShowCreateModal}
            disabled={false}
          />
        </div>
        <div className="company-management-panel">
          {loading && <LoadingPanel />}
          {hasError && !loading && (
            <ErrorPanel
              message="Error loading companies..."
              messageColour="white"
            />
          )}
          {!hasError && !loading && companies && (
            <div data-testid="company-table" className="company-table">
              <CheckboxTable
                columnHeaders={['Company Name']}
                toggleSelectAll={toggleAllCompanies}
                rowCheckBoxClicked={modifyselectedIds}
                data={detailsToDisplay}
                selectedIds={selectedIds}
                rowClicked={goToCompanyView}
              />
              <DeleteModal
                loading={deleteLoading}
                error={deleteError}
                success={deleteSuccess}
                visible={showDeleteModal}
                onClose={toggleShowDeleteModal}
                title="Delete Companies"
                onDelete={onDeleteCompanies}
                content={
                  <div>
                    <p>
                      This action will delete all data associated with the
                      Company. This includes Users, Vessels, Alert
                      Configurations etc.
                    </p>
                    <span>
                      Are you sure you want to delete the following companies?:
                    </span>
                    <ul role="delete-companies-list">
                      {companies
                        .filter((company: any) =>
                          selectedIds.some(
                            (selectedId: string) =>
                              selectedId === company.company_id
                          )
                        )
                        .map((company: any) => (
                          <li key={company.company_id}>
                            {company.company_name}
                          </li>
                        ))}
                    </ul>
                  </div>
                }
              />
              <CreateCompanyModal
                error={createCompanyError}
                loading={createCompanyLoading}
                success={createCompanySuccess}
                onCreate={onCreateCompany}
                visible={showCreateModal}
                onClose={toggleShowCreateModal}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ManageCompanies;
