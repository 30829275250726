import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import Modal from 'react-modal';
import './simple-modal.scss';

interface SimpleModalProps {
  showModal: boolean;
  closeModal: () => void;
  title: string;
  content: ReactNode;
  className: string;
}

function SimpleModal(props: SimpleModalProps) {
  const {
    showModal,
    closeModal,
    title: modalTitle,
    content: modalContent,
    className,
  } = props;

  return (
    <Modal
      isOpen={showModal}
      className={`simple-modal ${className}`}
      overlayClassName="simple-modal-overlay"
      appElement={document.getElementById('root') as HTMLElement}
      ariaHideApp={process.env.NODE_ENV !== 'test'}
    >
      <div className="modal-header">
        <h2>{modalTitle}</h2>
        <button
          data-testid="close-modal"
          className="close-modal"
          type="button"
          onClick={closeModal}
        >
          <FontAwesomeIcon
            className="close-modal-icon fa-2x"
            icon={faCircleXmark}
          />
        </button>
      </div>
      <div>{modalContent}</div>
    </Modal>
  );
}

export default SimpleModal;
