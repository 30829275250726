/* eslint-disable react/require-default-props */
import mapboxgl from 'mapbox-gl';
import {
  faFire,
  faLocationCrosshairs,
  faLocationDot,
  faRoute,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './history-result-card.scss';
import VesselHistoryController from '../../vessel-history-controller.utils';
import LayerToggle from '../../../../common-components/layer-toggle/layer-toggle';
import MapHelpers from '../../../../map/map.utils';

interface HistoryResultCardProps {
  imo?: number | null;
  mmsi?: number | null;
  name?: string | null;
  coordinates?: mapboxgl.LngLatLike | null;
  vesselId: string;
}

export const NOT_AVAILABLE = 'Unavailable';

function HistoryResultCard(props: HistoryResultCardProps) {
  const { name, imo, mmsi, vesselId, coordinates } = props;

  return (
    <div className="history-result-card" data-testid="history-result-card">
      <div
        className="history-result-card-label"
        data-testid="history-result-card-label"
      >
        <b>Name:</b> {name || NOT_AVAILABLE}
        <div className="history-result-card-controls">
          <LayerToggle
            sources={[
              VesselHistoryController.layerList.VESSELS.getLayerKey(vesselId),
            ]}
            untoggledClassName="control-inactive"
            label="Vessels"
          />
          <LayerToggle
            sources={[
              VesselHistoryController.layerList.JOURNEYS.getLayerKey(vesselId),
            ]}
            untoggledClassName="control-inactive"
            label="Vessel Journeys"
            untoggledIcon={faRoute}
            toggledIcon={faRoute}
          />
          <LayerToggle
            sources={[
              VesselHistoryController.layerList.AIS_POSITIONS.getLayerKey(
                vesselId
              ),
            ]}
            untoggledClassName="control-inactive"
            label="AIS Positions"
            untoggledIcon={faLocationCrosshairs}
            toggledIcon={faLocationCrosshairs}
          />
          <LayerToggle
            sources={[
              VesselHistoryController.layerList.AIS_HEATMAPS.getLayerKey(
                vesselId
              ),
            ]}
            untoggledClassName="control-inactive"
            label="Heatmaps"
            untoggledIcon={faFire}
            toggledIcon={faFire}
          />
          <FontAwesomeIcon
            data-testid="go-to-current-vessel"
            className="history-result-card-control"
            icon={faLocationDot}
            title="Go To Current Vessel"
            onClick={() => coordinates && MapHelpers.zoomToPoint(coordinates)}
          />
        </div>
      </div>
      <div
        className="history-result-card-info"
        data-testid="history-result-card-info"
      >
        <div data-testid="history-result-card-imo">
          <b>IMO:</b> {imo || NOT_AVAILABLE}
        </div>
        <div data-testid="history-result-card-mmsi">
          <b>MMSI:</b> {mmsi || NOT_AVAILABLE}
        </div>
      </div>
    </div>
  );
}

export default HistoryResultCard;
