import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AdminPage from './admin/admin-page/admin-page';
import CompanyView from './admin/admin-page/manage-companies/company-view/company-view';
import ManageCompanies from './admin/admin-page/manage-companies/manage-companies';
import ManageVessels from './admin/admin-page/manage-vessels/manage-vessels';
import App from './App';
import ErrorPage from './common-components/error-components/error-page/error-page';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import store from './store';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/admin',
    element: <AdminPage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'companies',
        element: <ManageCompanies />,
        errorElement: <ErrorPage />,
      },
      {
        path: 'companies/:id',
        element: <CompanyView />,
        errorElement: <ErrorPage />,
      },

      {
        path: 'vessels',
        element: <ManageVessels />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
