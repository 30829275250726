import mapboxgl from 'mapbox-gl';
import MapLayer from './map-layer.enum';
import addVesselsLayer from './vessel-utils/add-vessels-layer';

import PathIsocelesTriangle from '../../assets/icons/vessels/path-triangle-isosceles.png';
import CurrentAlertVesselHighlighted from '../../assets/icons/vessels/vessel-white-highlighted.png';
import CurrentAlertVessel from '../../assets/icons/vessels/vessel-white-standard.png';
import addRasterLayer from './layer-utils/add-raster-layer';
import MapLayerIcon from './map-layer-icon';
import addMapImage from './vessel-utils/add-image-to-layer';
import OpenSeaMapsController from '../../maritime-menu-options/open-sea-maps-panel/open-sea-maps-controller.utils';

namespace MapLayerManager {
  interface IGroupLayerMetaData {
    groupLayer: string;
  }

  export const getAllLayers = (map: mapboxgl.Map) => {
    // layer styles
    const { layers } = map.getStyle();
    return layers.map((layer) => map.getLayer(layer.id));
  };

  // remove layer data and layer view from map
  export const destroyLayer = (map: mapboxgl.Map, layer: string) => {
    map.removeLayer(layer);
    map.removeSource(layer);
  };

  export const groupLayerMetaData = (
    groupLayer: string
  ): IGroupLayerMetaData => ({ groupLayer });
  export const findLayersByGroupLayerId = (
    map: mapboxgl.Map,
    groupLayer: string
  ) => {
    const allLayers = getAllLayers(map);

    return allLayers.filter(
      (layer: mapboxgl.Layer) => layer.metadata?.groupLayer === groupLayer
    );
  };

  export const loadAllLayers = async (map: mapboxgl.Map) => {
    addMapImage(CurrentAlertVessel, MapLayerIcon.PLAIN_VESSEL.NORMAL, map);
    addMapImage(
      CurrentAlertVesselHighlighted,
      MapLayerIcon.PLAIN_VESSEL.HIGHLIGHTED,
      map
    );
    addMapImage(PathIsocelesTriangle, MapLayerIcon.HISTORIC_POSITION, map);

    await addVesselsLayer(map, MapLayer.CURRENTLY_ALERTING_VESSELS);
    await addVesselsLayer(map, MapLayer.MY_FLEET_VESSELS);
    await addRasterLayer(map, MapLayer.OPEN_SEA_MAP, [
      'https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png',
    ]);

    await OpenSeaMapsController.loadMessageBanners(map);
  };
}

export default MapLayerManager;
