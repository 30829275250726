/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  faCircleCheck,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Papa from 'papaparse';
import { useState } from 'react';
import ErrorPanel from '../../../../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../../../../common-components/loading-panel/loading-panel';
import SimpleModal from '../../../../../common-components/simple-modal/simple-modal';

import './upload-vessels-modal.scss';

interface UploadVesselsModalProps {
  visible: boolean;
  onClose: () => void;
  onUpload: (fileToUpload: { data: any }) => void;
  loading: boolean;
  success: boolean;
  error: boolean;
}

interface RenderUploadModalFormProps {
  // TODO - check if we can put a custom type on data
  onUpload: (fileToUpload: { data: any }) => void;
  loading: boolean;
  success: boolean;
  error: boolean;
}

function RenderUploadModalForm(props: RenderUploadModalFormProps) {
  const { onUpload, loading, success, error } = props;

  const [csvFile, setCsvFile] = useState<Papa.LocalFile>();

  const handleChange = (event: any) => {
    setCsvFile(event.target.files[0] as Papa.LocalFile);
  };

  const importCSV = () => {
    Papa.parse(csvFile as Papa.LocalFile, {
      header: true,
      skipEmptyLines: true,

      complete: onUpload,
    });
  };

  return (
    <div>
      {loading && (
        <div className="delete-modal-loading-panel">
          <LoadingPanel />
          <span>Uploading Vessels...</span>
        </div>
      )}
      {!loading && error && (
        <div className="delete-modal-error-panel">
          <ErrorPanel
            message="Error creating vessels..."
            messageColour="black"
          />
        </div>
      )}
      {!loading && !error && success && (
        <div className="delete-modal-success-panel">
          <FontAwesomeIcon
            className="success-circle fa-5x"
            icon={faCircleCheck}
          />
          <span>Success</span>
        </div>
      )}
      {!loading && !error && !success && (
        <div className="upload-vessels-modal-content">
          <FontAwesomeIcon
            className="caution-triangle fa-5x"
            icon={faTriangleExclamation}
          />
          <p>
            Caution - uploading a new Vessel CSV will overwrite the current list
            of vessels:
          </p>
          <div className="upload-vessels-form">
            <input
              data-testid="vessel-csv-upload"
              className="csv-input"
              type="file"
              name="file"
              placeholder="Select CSV..."
              onChange={(event) => handleChange(event)}
            />
            <div className="confirm-upload-container">
              <button
                className="confirm-vessel-upload-button"
                type="button"
                onClick={importCSV}
              >
                Confirm Upload
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function UploadVesselsModal(props: UploadVesselsModalProps) {
  const { visible, onClose, onUpload, loading, success, error } = props;

  return (
    <SimpleModal
      closeModal={onClose}
      title="Upload new vessel list"
      showModal={visible}
      className="upload-new-vessel-modal"
      content={
        <RenderUploadModalForm
          onUpload={onUpload}
          error={error}
          loading={loading}
          success={success}
        />
      }
    />
  );
}

export default UploadVesselsModal;
