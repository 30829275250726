import LayerList from '../../common-components/layer-list/layer-list';
import useVesselHistoryLayerList from './use-vessel-history-layer-list';

function HistoryPanelLayerList() {
  const vesselHistoryLayerList = useVesselHistoryLayerList();

  return (
    <div className="history-panel-layer-list" data-testid="history-panel">
      <LayerList
        layers={[
          {
            label: 'Vessels',
            sources: vesselHistoryLayerList.VESSELS,
          },
          {
            label: 'Vessel Journeys',
            sources: vesselHistoryLayerList.JOURNEYS,
          },
          {
            label: 'AIS Data Gaps',
            sources: vesselHistoryLayerList.AIS_DATA_GAPS,
          },
          {
            label: 'AIS Positions',
            sources: vesselHistoryLayerList.AIS_POSITIONS,
          },
          {
            label: 'AIS Heatmaps',
            sources: vesselHistoryLayerList.AIS_HEATMAPS,
          },
        ]}
      />
    </div>
  );
}

export default HistoryPanelLayerList;
