/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/destructuring-assignment */
// import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Accordion from '../../common-components/accordian/accordian';
import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../common-components/loading-panel/loading-panel';
import { useAppSelector } from '../../hooks';
import VesselInformationCard from './vessel-information-card/vessel-information-card';
import VesselInformationLabel from './vessel-information-card/vessel-information-label/vessel-information-label';
import {
  BUILD_HISTORY_LABELS,
  CAPACITY_LABELS,
  DESIGN_LABELS,
  DIMENSION_LABELS,
  ENGINE_LABELS,
  REGISTRATION_LABELS,
} from './vessel-information.labels';
import { IVesselInformation } from './vessel-information.model';
import './vessel-information.scss';

function RenderInformation(vesselInfo: IVesselInformation) {
  return (
    <div data-testid="vessel-information">
      <h3>General Information: </h3>
      <div className="vessel-information">
        <VesselInformationLabel
          dataStyle="#2abbaf"
          title="Ship Name"
          data={vesselInfo.staticData.name}
        />
        <VesselInformationLabel
          dataStyle="#2abbaf"
          title="Flag"
          data={vesselInfo.staticData.flag}
        />
        <VesselInformationLabel
          dataStyle="#2abbaf"
          title="IMO"
          data={vesselInfo.staticData.imo}
        />
        <VesselInformationLabel
          dataStyle="#2abbaf"
          title="MMSI"
          data={vesselInfo.staticData.mmsi}
        />
        <VesselInformationLabel
          dataStyle="#2abbaf"
          title="Destination"
          data={vesselInfo.currentVoyage.destination}
        />
        <VesselInformationLabel
          dataStyle="#2abbaf"
          title="ETA"
          data={vesselInfo.currentVoyage.eta}
        />
      </div>
      <h3>Additional Information</h3>
      <div className="additional-vessel-information">
        <Accordion
          title="Design"
          content={
            <VesselInformationCard
              labels={DESIGN_LABELS}
              information={vesselInfo.design}
            />
          }
        />
        <Accordion
          title="Engine"
          content={
            <VesselInformationCard
              labels={ENGINE_LABELS}
              information={vesselInfo.propulsion}
            />
          }
        />
        <Accordion
          title="Dimensions"
          content={
            <VesselInformationCard
              labels={DIMENSION_LABELS}
              information={vesselInfo.dimensions}
            />
          }
        />
        <Accordion
          title="Capacity"
          content={
            <VesselInformationCard
              labels={CAPACITY_LABELS}
              information={vesselInfo.capacity}
            />
          }
        />
        <Accordion
          title="Registration"
          content={
            <VesselInformationCard
              labels={REGISTRATION_LABELS}
              information={vesselInfo.registration}
            />
          }
        />
        <Accordion
          title="Build History"
          content={
            <VesselInformationCard
              labels={BUILD_HISTORY_LABELS}
              information={vesselInfo.history}
            />
          }
        />
      </div>
    </div>
  );
}

function VesselInformation() {
  // const { vesselInfo } = props;
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [vesselInfo, setVesselInfo] =
    useState<IVesselInformation | null | void>(null);
  // let vesselInfo: IVesselInformation | AxiosResponse<any, any> | null = null;
  // const vesselInfo = VESSEL_INFORMATION_STUB;
  const selectedVessel = useAppSelector(
    (state) => state.vesselDossier.selectedVessel
  );

  const ERROR_MESSAGE =
    'Unable to fetch vessel information. Please try again later...';

  // This is called twice due to strict mode when in dev
  useEffect(() => {
    if (!selectedVessel.properties) {
      return;
    }

    setLoading(true);

    const MARITIME_HOT_URL_STEM =
      process.env.REACT_APP_MARITIME_HOT_URL || 'http://127.0.0.1:3001';

    axios
      .get(
        `${MARITIME_HOT_URL_STEM}/vessels/${selectedVessel.properties?.mmsi}`,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
          },
        }
      )
      .then((response) => {
        setVesselInfo(response.data);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_error) => {
        setHasError(true);
      })
      .finally(() => {
        setLoading(false);
        // console.log(vesselInfo);
      });
  }, [selectedVessel]);

  return (
    <div className="vessel-information-container">
      {loading && <LoadingPanel />}
      {!loading && hasError && (
        <ErrorPanel message={ERROR_MESSAGE} messageColour="white" />
      )}
      {!loading &&
        !hasError &&
        vesselInfo !== null &&
        RenderInformation(vesselInfo as IVesselInformation)}
    </div>
  );
}

export default VesselInformation;
