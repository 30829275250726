import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { toggleSecondaryMenu } from '../menu.slice';
import './secondary-pane.scss';

interface ISecondaryPaneProps {
  component: JSX.Element;
}

const mountedStyle = {
  animation: 'inAnimation 270ms ease-in',
};
const unmountedStyle = {
  animation: 'outAnimation 270ms ease-out',
  animationFillMode: 'forwards',
};

function SecondaryPane(props: ISecondaryPaneProps) {
  const [isMounted, setIsMounted] = useState(true);

  const { component } = props;
  const dispatch = useAppDispatch();

  return (
    <div
      data-testid="secondary-pane-container"
      className="secondary-pane-container"
      style={isMounted ? mountedStyle : unmountedStyle}
      onAnimationEnd={() => {
        if (!isMounted) dispatch(toggleSecondaryMenu(false));
      }}
    >
      <div className="secondary-pane-button-container">
        <button
          data-testid="close-secondary-pane-button"
          className="close-secondary-pane-button"
          type="button"
          title="Close"
          onClick={() => setIsMounted(false)}
        >
          <FontAwesomeIcon
            className="close-panel-icon fa-2x"
            icon={faCircleXmark}
          />
        </button>
      </div>

      <div
        style={isMounted ? mountedStyle : unmountedStyle}
        className="secondary-pane-container-content"
      >
        {component}
      </div>
    </div>
  );
}

export default SecondaryPane;
