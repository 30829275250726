/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_MY_FLEET_STATE = {
  loading: false,
  error: false,
  myFleet: null,
};

export const MyFleetSlice = createSlice({
  name: 'myFleet',
  initialState: INITIAL_MY_FLEET_STATE,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = false;
    },
    setError: (state) => {
      state.error = true;
      state.loading = false;
    },
    setMyFleet: (state, action) => {
      state.myFleet = action.payload;
      state.loading = false;
      state.error = false;
    },
  },
});

// Action creators are generated for each case reducer function

export const { setLoading, setError, setMyFleet } = MyFleetSlice.actions;

export default MyFleetSlice.reducer;
