/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  faBars,
  faBell,
  faClock,
  faCompassDrafting,
  faShip,
  faSquareCaretLeft,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';
import useVesselHistoryLayerList from '../../maritime-menu-options/history-panel/use-vessel-history-layer-list';

import { EMenuItems, setSelectedOption, toggleMainMenu } from '../menu.slice';
import SideMenuItem from './side-menu-item/side-menu-item';

import './side-menu.scss';

function SideMenu() {
  const dispatch = useAppDispatch();
  const menuOpen = useAppSelector((state) => state.menu.menuOpen);
  const selectedOption = useAppSelector((state) => state.menu.selectedOption);
  const { alertingVessels } = useAppSelector((state) => state.alertingVessels);
  const { myFleet } = useAppSelector((state) => state.myFleet);
  const { historicVesselPoints } = useAppSelector(
    (state) => state.historyPanel
  );

  const vesselHistoryLayerList = useVesselHistoryLayerList();

  useEffect(() => {
    const sideMenu = document.getElementById('side-menu');
    sideMenu?.classList.remove('side-menu-closed');
  }, []);

  return (
    <div
      data-testid="side-menu"
      id="side-menu"
      className={`side-menu ${
        menuOpen ? 'side-menu-open' : 'side-menu-closed'
      }`}
    >
      <div
        data-testid="main-menu"
        className="side-menu-options"
        onClick={() => dispatch(toggleMainMenu())}
      >
        <div className="highlight-menu-item">
          {!menuOpen ? (
            <FontAwesomeIcon
              data-testid="bars-menu"
              className="side-menu-icon fa-2x"
              icon={faBars}
            />
          ) : (
            <div className="side-menu-expanded-header">
              <FontAwesomeIcon
                data-testid="left-arrow-menu"
                className="side-menu-icon fa-2x"
                icon={faSquareCaretLeft}
              />
              <div className="side-menu-title">
                <span>Maritime Menu</span>
              </div>
            </div>
          )}
        </div>
      </div>

      <SideMenuItem
        selected={selectedOption === EMenuItems.MYF}
        iconName={faShip}
        itemName="My Fleet"
        menuOpen={menuOpen}
        onClick={() => dispatch(setSelectedOption(EMenuItems.MYF))}
        layerSources={[MapLayer.MY_FLEET_VESSELS]}
        layerToggleDisabled={myFleet === null}
      />
      <SideMenuItem
        selected={selectedOption === EMenuItems.ALERTS}
        iconName={faBell}
        itemName="Alerts"
        menuOpen={menuOpen}
        onClick={() => dispatch(setSelectedOption(EMenuItems.ALERTS))}
        layerSources={[MapLayer.CURRENTLY_ALERTING_VESSELS]}
        layerToggleDisabled={alertingVessels === null}
      />
      <SideMenuItem
        selected={selectedOption === EMenuItems.HISTORY}
        iconName={faClock}
        itemName="History"
        menuOpen={menuOpen}
        onClick={() => dispatch(setSelectedOption(EMenuItems.HISTORY))}
        layerSources={[...Object.values(vesselHistoryLayerList).flat()]}
        layerToggleDisabled={!historicVesselPoints.length}
      />
      <SideMenuItem
        selected={selectedOption === EMenuItems.OPEN_SEA_MAP}
        iconName={faCompassDrafting}
        itemName="Open Sea Maps"
        menuOpen={menuOpen}
        onClick={() => dispatch(setSelectedOption(EMenuItems.OPEN_SEA_MAP))}
        layerSources={[
          MapLayer.OPEN_SEA_MAP,
          MapLayer.OPEN_SEA_MAP_LABELS,
          MapLayer.OPEN_SEA_MAP_TOP_BANNER,
          MapLayer.OPEN_SEA_MAP_BOTTOM_BANNER,
        ]}
      />
    </div>
  );
}

export default SideMenu;
