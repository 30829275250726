import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import './heatmap-key.scss';
import MapLayerPaint from '../../map/map-layer-manager/map-layer-paint';

interface HeatmapKeyProps {
  label: string;
}

// build gradient string from Heatmap Layer Style
const buildGradientString = () => {
  const heatmapGradient = MapLayerPaint.DEFAULT_HEATMAP_GRADIENT;
  const colourCount = heatmapGradient.length / 2;
  const gradientList = [];

  for (let i = 0; i < colourCount; i += 1) {
    const colour = heatmapGradient[i * 2 + 1];
    const percentage = Number(heatmapGradient[i * 2]);

    gradientList.push(`${colour} ${percentage * 100}%`);
  }

  return `linear-gradient( to right, ${gradientList.join(', ')})`;
};

function HeatmapKey(props: HeatmapKeyProps) {
  const { label } = props;
  const background = buildGradientString();

  return (
    <div className="heatmap-container">
      <div className="heatmap-key" style={{ background }} />
      <div className="heatmap-key-label">
        {label} &nbsp;
        <FontAwesomeIcon icon={faArrowRightLong} />
      </div>
    </div>
  );
}

export default HeatmapKey;
