import LayerToggle from '../layer-toggle/layer-toggle';

import './layer-list.scss';

type Layer = {
  label: string;
  sources: string[];
};

interface LayerListProps {
  layers: Layer[];
}

function LayerList(props: LayerListProps) {
  const { layers } = props;

  return (
    <div className="layer-list" data-testid="layer-list">
      {layers.map((layer: Layer) => (
        <div className="layer-list-item" key={layer.label}>
          <span className="layer-list-item-label">{layer.label}</span>
          <span className="layer-list-item-toggle">
            <LayerToggle sources={layer.sources} label={layer.label} />
          </span>
        </div>
      ))}
    </div>
  );
}

export default LayerList;
