import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './icon-button.scss';

interface IconButtonProps {
  label: string;
  tooltip: string;
  iconDef: IconDefinition;
  // eslint-disable-next-line react/require-default-props
  classNames?: string;
  onClick: (() => {}) | (() => void);
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
}

function IconButton({
  label,
  tooltip,
  iconDef,
  classNames = '',
  disabled = false,
  onClick,
}: IconButtonProps) {
  return (
    <button
      data-testid="icon-button"
      type="button"
      title={tooltip}
      className={`rectangle-button ${classNames}`}
      onClick={onClick}
      disabled={disabled}
    >
      <FontAwesomeIcon className="rectangle-button-icon fa-1x" icon={iconDef} />
      {label}
    </button>
  );
}

export default IconButton;
