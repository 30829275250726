import { faPlus, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CheckboxTable from '../../../../common-components/checkbox-table/checkbox-table';
import DeleteModal from '../../../../common-components/delete-modal/delete-modal';
import ErrorPanel from '../../../../common-components/error-components/error-panel/error-panel';
import IconButton from '../../../../common-components/icon-button/icon-button';
import LoadingPanel from '../../../../common-components/loading-panel/loading-panel';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
  setAllVessels,
  setAllVesselsError,
  setAllVesselsLoading,
  VesselData,
} from '../../admin.slice';
import './all-vessels-panel.scss';
import UploadVesselsModal from './upload-vessels-modal/upload-vessels-modal';

function mapToTableObjects(vessels: any[] | null) {
  const detailsToDisplay: Object[] = [];
  if (vessels) {
    vessels.forEach((vessel) => {
      detailsToDisplay.push({
        id: vessel.vessel_id,
        vessel_name: vessel.vessel_name,
        imo: vessel.imo,
        current_mmsi: vessel.current_mmsi,
      });
    });
  }
  return detailsToDisplay;
}

function AllVessels() {
  const GEONIUS_BACKEND_URL_STEM = process.env.REACT_APP_GEONIUS_BACKEND_URL;
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.admin.allVesselsLoading);
  const hasError = useAppSelector((state) => state.admin.allVesselsError);
  const allVessels: VesselData[] = useAppSelector(
    (state) => state.admin.allVessels
  );

  const detailsToDisplay = mapToTableObjects(allVessels);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const [deleteLoading, setShowDeleteLoading] = useState<boolean>(false);
  const [deleteError, setShowDeleteError] = useState<boolean>(false);
  const [deleteSuccess, setShowDeleteSuccess] = useState<boolean>(false);

  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<boolean>(false);
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);

  useEffect(() => {
    dispatch(setAllVesselsLoading(true));
    axios
      .get(`${GEONIUS_BACKEND_URL_STEM}/vessels`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then((response) => {
        dispatch(setAllVessels(response.data));
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_error) => {
        dispatch(setAllVesselsError());
      });
  }, []);

  const getVesselIdsToDelete = () =>
    allVessels
      .filter((vessel: VesselData) =>
        selectedIds.some(
          (selectedId: string) => selectedId === vessel.vessel_id
        )
      )
      .map(({ vessel_id }) => vessel_id);

  const onDeleteVessels = () => {
    setShowDeleteLoading(true);
    axios
      .delete(`${GEONIUS_BACKEND_URL_STEM}/vessels`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          vessel_ids: getVesselIdsToDelete(),
        },
      })
      .then(() => {
        setShowDeleteLoading(false);
        setShowDeleteSuccess(true);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_error) => {
        setShowDeleteLoading(false);
        setShowDeleteError(true);
      });
  };

  const toggleShowUploadModal = () => {
    setShowUploadModal(!showUploadModal);
  };

  const toggleShowDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
    if (!showDeleteModal) {
      setShowDeleteLoading(false);
      setShowDeleteError(false);
      setShowDeleteSuccess(false);
    }
  };

  const modifyselectedIds = (id: string) => {
    // if compnay is in the selected company list already then delete it
    if (selectedIds.some((selectedId) => selectedId === id)) {
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter(
          (previouslySelectedId) => previouslySelectedId !== id
        )
      );
    } else {
      setSelectedIds((oldArray) => [...oldArray, id]);
    }
  };

  const toggleSelectAllVessels = () => {
    if (selectedIds.length < allVessels.length) {
      allVessels.forEach((vessel: VesselData) => {
        // if vessel isn't already a part of selectedvessels then add it
        if (
          !selectedIds.some(
            (selectedId: string) => selectedId === vessel.vessel_id
          )
        ) {
          setSelectedIds((oldArray) => [...oldArray, vessel.vessel_id]);
        }
      });
    } else {
      setSelectedIds([]);
    }
  };

  const uploadNewVessels = (result: { data: any }) => {
    setUploadLoading(true);
    const { data } = result;
    axios
      .post(`${GEONIUS_BACKEND_URL_STEM}/vessels`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'text/csv',
        },
        data: {
          company_id: '0b474cdb-3a8c-45b3-91c1-5bdfef855051',
          vessels: data,
        },
      })
      .then(() => {
        setUploadLoading(false);
        setUploadSuccess(true);
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_error) => {
        setUploadLoading(false);
        setUploadError(true);
      });
  };

  return (
    <div className="all-vessels-container">
      <h2>All Vessels</h2>

      <div className="all-vessels-buttons">
        <IconButton
          label="Delete"
          tooltip="Delete vessels"
          iconDef={faTrash}
          onClick={toggleShowDeleteModal}
          classNames="all-vessels-delete"
          disabled={selectedIds.length === 0}
        />
        <IconButton
          label="Upload"
          tooltip="Upload vessels"
          iconDef={faUpload}
          onClick={toggleShowUploadModal}
          classNames="all-vessels-upload"
        />
        <IconButton
          label="Add to list"
          tooltip="Add vessel to custom list"
          iconDef={faPlus}
          onClick={() => {}}
          classNames="all-vessels-add-to-list"
        />
      </div>
      {loading && <LoadingPanel />}
      {hasError && !loading && (
        <ErrorPanel
          message="Error loading all Vessels..."
          messageColour="white"
        />
      )}
      {!loading && !hasError && allVessels && (
        <div className="all-vessels-table">
          <CheckboxTable
            columnHeaders={['Name', 'IMO', 'MMSI']}
            toggleSelectAll={toggleSelectAllVessels}
            rowCheckBoxClicked={modifyselectedIds}
            data={detailsToDisplay}
            selectedIds={selectedIds}
            rowClicked={() => {}}
          />
          <UploadVesselsModal
            error={uploadError}
            loading={uploadLoading}
            success={uploadSuccess}
            onUpload={uploadNewVessels}
            visible={showUploadModal}
            onClose={toggleShowUploadModal}
          />
          <DeleteModal
            loading={deleteLoading}
            success={deleteSuccess}
            error={deleteError}
            onClose={toggleShowDeleteModal}
            title="Delete Vessels"
            visible={showDeleteModal}
            onDelete={onDeleteVessels}
            content={
              <div>
                <p>
                  Caution - deleting vessels will remove them entirely from the
                  system, they will no longer appear in alert lists etc.
                </p>
                <span>Are you sure you want to delete these vessels?:</span>

                <ul>
                  {allVessels
                    .filter((vessel: VesselData) =>
                      selectedIds.some(
                        (selectedId: string) => selectedId === vessel.vessel_id
                      )
                    )
                    .map((vessel: any) => (
                      <li key={vessel.vessel_id}>
                        {vessel.vessel_name}, {vessel.imo},{' '}
                        {vessel.current_mmsi}
                      </li>
                    ))}
                </ul>
              </div>
            }
          />
        </div>
      )}
    </div>
  );
}

export default AllVessels;
