enum MapLayer {
  MY_FLEET_VESSELS = 'my-fleet-vessels',
  CURRENTLY_ALERTING_VESSELS = 'currently-alerting-vessels',
  VESSEL_HISTORY_AIS_POSITIONS = 'vessel-history-ais-positions',
  VESSEL_HISTORY_JOURNEYS = 'vessel-history-journeys',
  VESSEL_HISTORY_HEATMAPS = 'vessel-history-heatmaps',
  VESSEL_HISTORY_VESSELS = 'vessel-history-vessels',
  VESSEL_HISTORY_AIS_DATA_GAPS = 'vessel-history-ais-data-gaps',
  OPEN_SEA_MAP = 'open-sea-map',
  OPEN_SEA_MAP_TOP_BANNER = 'open-sea-map-top-banner',
  OPEN_SEA_MAP_BOTTOM_BANNER = 'open-sea-map-bottom-banner',
  OPEN_SEA_MAP_LABELS = 'open-sea-map-labels',
}

export default MapLayer;
