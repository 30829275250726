import mapboxgl from 'mapbox-gl';
import MapLayerVisibility from '../map-layer-visibility.enum';
import MapLayer from '../map-layer.enum';
import addSimpleGeoJsonSource from '../vessel-utils/add-simple-geojson-source';

function addPolygonLayer(
  map: mapboxgl.Map,
  layer: MapLayer | string,
  paint: mapboxgl.FillPaint = {}
) {
  // eslint-disable-next-line no-new
  new Promise((resolve) => {
    addSimpleGeoJsonSource(map, layer);
    map.addLayer({
      id: layer,
      type: 'fill',
      source: layer,
      layout: {
        visibility: MapLayerVisibility.NOT_VISIBLE,
      },
      paint,
    });
    resolve(map);
  });
}

export default addPolygonLayer;
