import ErrorPanel from '../../common-components/error-components/error-panel/error-panel';
import { useAppSelector } from '../../hooks';
import AlertPanel from '../../maritime-menu-options/alert-panel/alert-panel';
import HistoryPanel from '../../maritime-menu-options/history-panel/history-panel';
import MyFleet from '../../maritime-menu-options/my-fleet-panel/myFleet';
import OpenSeaMapPanel from '../../maritime-menu-options/open-sea-maps-panel/open-sea-maps';
import { EMenuItems } from '../menu.slice';
import SecondaryPane from '../secondary-pane/secondary-pane';
import SideMenu from '../side-menu/side-menu';
import './main-menu.scss';

function DeriveComponent(componentToDisplay: EMenuItems) {
  switch (componentToDisplay) {
    case EMenuItems.ALERTS: {
      return <AlertPanel />;
    }
    case EMenuItems.HISTORY: {
      return <HistoryPanel />;
    }
    case EMenuItems.MYF: {
      return <MyFleet />;
    }
    case EMenuItems.OPEN_SEA_MAP: {
      return <OpenSeaMapPanel />;
    }
    default: {
      return (
        <ErrorPanel message="Component not found." messageColour="white" />
      );
    }
  }
}

function MainMenu() {
  const secondaryMenuOpen = useAppSelector(
    (state) => state.menu.secondaryMenuOpen
  );

  const componentToDisplay = useAppSelector(
    (state) => state.menu.selectedOption
  );

  const component = DeriveComponent(componentToDisplay);

  return (
    <div data-testid="main-menu" className="main-menu">
      <SideMenu />
      {secondaryMenuOpen && <SecondaryPane component={component} />}
    </div>
  );
}

export default MainMenu;
