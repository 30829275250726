import geojsonExtent from '@mapbox/geojson-extent';
import mapboxgl, {
  LngLatBoundsLike,
  LngLatLike,
  MapboxGeoJSONFeature,
} from 'mapbox-gl';
import store from '../store';
import CustomMapEvents from './custom-map-events.enum';
import MapLayerVisibility from './map-layer-manager/map-layer-visibility.enum';

namespace MapHelpers {
  // Helps us access map outside of components
  export const getMapInstance = () => store.getState().map.map;

  export const setLayerFeatureIcon = (
    feature: MapboxGeoJSONFeature,
    icon: string
  ) => {
    const map = getMapInstance();

    // https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#match
    map.setLayoutProperty(feature.layer.id, 'icon-image', [
      'match',
      ['id'],
      feature.id,
      icon,
      // fallback icon. use default one here
      map.getLayoutProperty(feature.layer.id, 'icon-image'),
    ]);
  };

  export const isLayerVisible = (layerSource: string) => {
    const map = getMapInstance();
    return (
      map.getLayoutProperty(layerSource, 'visibility') ===
      MapLayerVisibility.VISIBLE
    );
  };

  export const LayerVisibilityChangeType = (layerId: string) =>
    `${layerId}_${CustomMapEvents.LAYER_VISIBILITY_CHANGE}`;

  const triggerLayerVisibilityChange = (layerId: string) => {
    const map = getMapInstance();
    map.fire(LayerVisibilityChangeType(layerId), map.getSource(layerId));
  };

  export const onLayerVisibilityChange = (
    layerId: string,
    callback: Function
  ) => {
    const map = getMapInstance();
    map.on(LayerVisibilityChangeType(layerId), () => callback());
  };

  export const removeLayerVisibilityEvent = (
    layerId: string,
    callback: Function
  ) => {
    const map = getMapInstance();
    map.off(LayerVisibilityChangeType(layerId), () => callback());
  };

  export const setLayerVisibility = (layerSource: string, visible: boolean) => {
    const map = getMapInstance();
    map.setLayoutProperty(
      layerSource,
      'visibility',
      visible ? MapLayerVisibility.VISIBLE : MapLayerVisibility.NOT_VISIBLE
    );

    triggerLayerVisibilityChange(layerSource);
  };

  export const zoomToPoint = (coordinates: mapboxgl.LngLatLike, zoom = 7) => {
    const map = getMapInstance();

    map.flyTo({
      zoom: Math.max(map.getZoom(), zoom),
      center: coordinates,
      speed: 0.5,
      curve: 2,
    });
  };

  export const createZoomPadding = (padding = 160) => {
    let left = padding;

    // Zoom to Feature and put in center of map viewport
    // TODO: Take in to account other menus / elements in front of map element.
    const app = document.querySelector('.App');
    const mainMenuEl = app?.querySelector('.menu-container');

    if (mainMenuEl) {
      const boundingClientRect = mainMenuEl.getBoundingClientRect();

      left += boundingClientRect.left + boundingClientRect.width;
    }

    return {
      padding: {
        top: padding,
        left,
        bottom: padding,
        right: padding,
      },
    };
  };

  export const zoomToLine = (point1: LngLatLike, point2: LngLatLike) => {
    const map = getMapInstance();
    const bounds = new mapboxgl.LngLatBounds(point1, point2);

    map.fitBounds(bounds, createZoomPadding());
  };

  export const zoomToFeatureCollection = (
    featureCollection: GeoJSON.FeatureCollection
  ) => {
    const map = getMapInstance();
    const bounds = geojsonExtent(featureCollection);

    map.fitBounds(bounds as LngLatBoundsLike, createZoomPadding());
  };
}

export default MapHelpers;
