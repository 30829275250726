import MapLayer from '../map-layer.enum';

function addRasterSource(
  map: mapboxgl.Map,
  layer: MapLayer | string,
  tileSources: string[]
) {
  map.addSource(layer, {
    type: 'raster',
    tiles: tileSources,
    tileSize: 256,
  });
}

export default addRasterSource;
