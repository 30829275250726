/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { MapboxGeoJSONFeature } from 'mapbox-gl';

export const INITAL_VESSEL_DOSSIER_STATE = {
  selectedVessel: {} as MapboxGeoJSONFeature,
};

export const VesselDossierSlice = createSlice({
  name: 'vessel-dossier',
  initialState: INITAL_VESSEL_DOSSIER_STATE,
  reducers: {
    setSelectedVessel: (state, action) => {
      state.selectedVessel = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const { setSelectedVessel } = VesselDossierSlice.actions;

export default VesselDossierSlice.reducer;
