import { GeoJSONSource } from 'mapbox-gl';
import { HistoricVesselPoint } from '../../../maritime-menu-options/history-panel/historic-vessel-point.model';
import GeoHelper from '../../../utils/geo-helpers.utils';
import MapHelpers from '../../map.utils';
import MapLayer from '../map-layer.enum';
import createVesselJourneyFeatures from './create-vessel-journey';

function setVesselJourney(
  points: HistoricVesselPoint[],
  layerId: MapLayer | string
) {
  const map = MapHelpers.getMapInstance();
  const journeys = createVesselJourneyFeatures(points);

  (map.getSource(layerId) as GeoJSONSource)?.setData(
    GeoHelper.createGeoJSON(journeys)
  );
}

export default setVesselJourney;
