/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  faCircleCheck,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import ErrorPanel from '../error-components/error-panel/error-panel';
import LoadingPanel from '../loading-panel/loading-panel';
import SimpleModal from '../simple-modal/simple-modal';

import './delete-modal.scss';

interface DeleteModalProps {
  title: string;
  visible: boolean;
  content: JSX.Element;
  onClose: () => void;
  onDelete: () => void;
  loading: boolean;
  success: boolean;
  error: boolean;
}

interface RenderDeleteModalFormProps {
  loading: boolean;
  success: boolean;
  error: boolean;
  modalContent: JSX.Element;
  onDelete: () => void;
}

function RenderDeleteModalForm(props: RenderDeleteModalFormProps) {
  const { modalContent, onDelete, loading, error, success } = props;
  const [disabled, setDisabled] = useState(true);

  return (
    <div className="delete-modal-content">
      {loading && (
        <div className="delete-modal-loading-panel">
          <LoadingPanel />
          <span>Deleting...</span>
        </div>
      )}
      {!loading && error && (
        <div className="delete-modal-error-panel">
          <ErrorPanel message="Error deleting items..." messageColour="black" />
        </div>
      )}
      {!loading && !error && success && (
        <div className="delete-modal-success-panel">
          <FontAwesomeIcon
            className="success-circle fa-5x"
            icon={faCircleCheck}
          />
          <span>Success</span>
        </div>
      )}
      {!loading && !error && !success && (
        <div>
          <FontAwesomeIcon
            className="delete-modal-caution-triangle fa-5x"
            icon={faTriangleExclamation}
          />
          <div className="">{modalContent}</div>
          <div className="delete-confirm">
            <span>
              If you are sure, type &apos;delete&apos; below and click the
              delete button.
            </span>
            <input
              aria-label="delete-confirmation-input"
              type="text"
              id="confirm-delete-input"
              onChange={(e) =>
                e.target.value === 'delete'
                  ? setDisabled(false)
                  : setDisabled(true)
              }
            />
          </div>
          <div className="delete-modal-buttons">
            <button
              type="button"
              className="delete-modal-button"
              disabled={disabled}
              onClick={onDelete}
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function DeleteModal(props: DeleteModalProps) {
  const {
    title,
    visible,
    onClose,
    content,
    onDelete,
    loading,
    success,
    error,
  } = props;
  return (
    <SimpleModal
      closeModal={onClose}
      title={title}
      showModal={visible}
      className="delete-modal"
      content={
        <RenderDeleteModalForm
          loading={loading}
          error={error}
          success={success}
          modalContent={content}
          onDelete={onDelete}
        />
      }
    />
  );
}

export default DeleteModal;
