/* eslint-disable jsx-a11y/label-has-associated-control */
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Form, Formik } from 'formik';
import ErrorPanel from '../../../../common-components/error-components/error-panel/error-panel';
import LoadingPanel from '../../../../common-components/loading-panel/loading-panel';
import SimpleModal from '../../../../common-components/simple-modal/simple-modal';
import './create-company-modal.scss';

export interface CreateCompanyFormValues {
  companyName: string;
}

interface CreateCompanyModalProps {
  loading: boolean;
  success: boolean;
  error: boolean;
  onCreate: (values: CreateCompanyFormValues) => void;
  visible: boolean;
  onClose: () => void;
}

interface RenderCreateCompanyFormProps {
  loading: boolean;
  success: boolean;
  error: boolean;
  onCreate: (values: CreateCompanyFormValues) => void;
}

function RenderCreateCompanyForm(props: RenderCreateCompanyFormProps) {
  const {
    loading: createCompanyLoading,
    success: createCompanySuccess,
    error: createCompanyError,
    onCreate,
  } = props;

  const validateCompanyName = (companyName: string) => {
    let error;
    if (!companyName) {
      error = 'Company name is required';
    } else {
      error = null;
    }
    return error;
  };

  return (
    <div>
      {createCompanyLoading && (
        <div className="create-company-modal-loading-panel">
          <LoadingPanel />
          <span>Creating Company...</span>
        </div>
      )}
      {!createCompanyLoading && createCompanyError && (
        <div className="create-company-modal-error-panel">
          <ErrorPanel
            message="Error creating companies..."
            messageColour="black"
          />
        </div>
      )}
      {!createCompanyLoading && !createCompanyError && createCompanySuccess && (
        <div className="create-company-modal-success-panel">
          <FontAwesomeIcon
            className="success-circle fa-5x"
            icon={faCircleCheck}
          />
          <span>Success</span>
        </div>
      )}
      {!createCompanyLoading && !createCompanyError && !createCompanySuccess && (
        <Formik
          initialValues={{
            companyName: '',
          }}
          onSubmit={(values: CreateCompanyFormValues) => {
            onCreate(values);
          }}
        >
          {({ errors, touched }) => (
            <Form className="create-company-form">
              <div className="company-form-errors">
                {errors.companyName && touched.companyName && (
                  <div className="company-error-message">
                    <span>{errors.companyName}</span>
                  </div>
                )}
              </div>
              <div className="create-company-form-panel">
                <label htmlFor="companyName" className="company-name-label">
                  Company Name:
                </label>
                <div className="company-form-field">
                  <Field
                    data-testid="company-name-input"
                    className={`company-name-input ${
                      errors.companyName && touched.companyName
                        ? 'company-error-field'
                        : ''
                    }`}
                    id="companyName"
                    name="companyName"
                    placeholder="Enter company name..."
                    validate={validateCompanyName}
                    aria-label="company-name-input"
                  />
                </div>
              </div>

              <button
                data-testid="create-company-submit-button"
                className="create-company-submit-button"
                type="submit"
              >
                Submit
              </button>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
}

function CreateCompanyModal(props: CreateCompanyModalProps) {
  const { visible, onClose, error, loading, success, onCreate } = props;

  return (
    <SimpleModal
      closeModal={onClose}
      title="Create Company"
      showModal={visible}
      className="create-company-modal"
      content={
        <RenderCreateCompanyForm
          error={error}
          loading={loading}
          success={success}
          onCreate={onCreate}
        />
      }
    />
  );
}

export default CreateCompanyModal;
