/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  faEye,
  faEyeSlash,
  IconDefinition,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import './item-toggle.scss';

export interface ItemToggleProps {
  onChange?: Function;
  defaultChecked?: boolean;
  disabled?: boolean;
  toggledTooltip?: string;
  untoggledTooltip?: string;
  toggledIcon?: IconDefinition;
  untoggledIcon?: IconDefinition;
  untoggledClassName?: string;
}

function ItemToggle(props: ItemToggleProps) {
  const {
    defaultChecked,
    onChange,
    disabled,
    toggledTooltip,
    untoggledTooltip,
    toggledIcon,
    untoggledIcon,
    untoggledClassName,
  } = props;
  const [isToggled, setIsToggled] = useState(defaultChecked);

  const onClick = () => {
    if (disabled) {
      return;
    }

    setIsToggled(!isToggled);

    if (onChange) {
      onChange(!isToggled);
    }
  };

  const getItemToolTip = () => {
    if (disabled) {
      return;
    }

    // eslint-disable-next-line consistent-return
    return isToggled ? untoggledTooltip : toggledTooltip;
  };

  const checkedIcon = toggledIcon || faEye;
  const uncheckedIcon = untoggledIcon || faEyeSlash;

  useEffect(() => {
    setIsToggled(defaultChecked);
  }, [defaultChecked]);

  return (
    <FontAwesomeIcon
      className={`item-toggle-icon fa-lg ${disabled && 'disabled'} ${
        isToggled ? 'toggled' : `untoggled ${untoggledClassName}`
      }`}
      data-testid="item-toggle"
      icon={isToggled ? checkedIcon : uncheckedIcon}
      onClick={() => onClick()}
      title={getItemToolTip()}
    />
  );
}

ItemToggle.defaultProps = {
  defaultChecked: true,
  disabled: false,
  onChange: () => {},
  toggledTooltip: '',
  untoggledTooltip: '',
  toggledIcon: faEye,
  untoggledIcon: faEyeSlash,
  untoggledClassName: '',
};

export default ItemToggle;
