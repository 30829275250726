import mapboxgl, { GeoJSONSource } from 'mapbox-gl';
import addPolygonLayer from '../../map/map-layer-manager/layer-utils/add-polygon-layer';
import { addTextLayer } from '../../map/map-layer-manager/layer-utils/add-text-layer';
import MapLayerPaint from '../../map/map-layer-manager/map-layer-paint';
import MapLayer from '../../map/map-layer-manager/map-layer.enum';

import OpenSeaMapLabels from './open-sea-maps-labels';
import OpenSeaMapBanners from './open-sea-maps-banners';

namespace OpenSeaMapsController {
  export const loadMessageBanners = (map: mapboxgl.Map) => {
    addPolygonLayer(
      map,
      MapLayer.OPEN_SEA_MAP_TOP_BANNER,
      MapLayerPaint.OPEN_SEA_MAP_BANNER
    );
    addPolygonLayer(
      map,
      MapLayer.OPEN_SEA_MAP_BOTTOM_BANNER,
      MapLayerPaint.OPEN_SEA_MAP_BANNER
    );
    addTextLayer(map, MapLayer.OPEN_SEA_MAP_LABELS);

    (map.getSource(MapLayer.OPEN_SEA_MAP_LABELS) as GeoJSONSource)?.setData(
      OpenSeaMapLabels as GeoJSON.FeatureCollection
    );

    (map.getSource(MapLayer.OPEN_SEA_MAP_TOP_BANNER) as GeoJSONSource)?.setData(
      OpenSeaMapBanners.features[0] as GeoJSON.Feature<GeoJSON.Geometry>
    );

    (
      map.getSource(MapLayer.OPEN_SEA_MAP_BOTTOM_BANNER) as GeoJSONSource
    )?.setData(
      OpenSeaMapBanners.features[1] as GeoJSON.Feature<GeoJSON.Geometry>
    );
  };
}

export default OpenSeaMapsController;
