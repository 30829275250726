import './App.scss';
import { Amplify } from '@aws-amplify/core';
import '@aws-amplify/ui-react/styles.css';
import logo from './assets/geollect-logo.png';
import FeedbackBar from './feedback-bar/feedback-bar';
import { useAppSelector } from './hooks';
import MainMenu from './main-menu/main-menu/main-menu';
import Map from './map/map';
import SearchBar from './search-bar/search-bar';
import UserButton from './user-settings/user-button';
import VesselDossier from './vessel-dossier/vessel-dossier';
import awsExports from './aws-exports';
import withAuthenticator from './auth-provider';

Amplify.configure(awsExports);

function App() {
  const showVesselDossier = useAppSelector(
    (state) => state.menu.vesselDossierOpen
  );
  return (
    <div className="App">
      <img className="company-logo" src={logo} alt="geollect logo" />
      <div className="menu-container">
        <MainMenu />
      </div>

      <div className="app-vessel-dossier-container">
        {showVesselDossier && <VesselDossier />}
      </div>

      <div className="app-container">
        <FeedbackBar />
        <Map />
        <SearchBar />
        <UserButton />
      </div>
    </div>
  );
}

export default withAuthenticator(App, {
  className: 'login-container',
  components: undefined,
  hideSignUp: false,
  initialState: 'signIn',
  passwordSettings: {
    passwordPolicyMinLength: 10,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
      'REQUIRES_UPPERCASE',
    ],
  },
  services: undefined,
  signUpAttributes: [],
  socialProviders: [],
  variation: undefined,
});
