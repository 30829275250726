import { Outlet } from 'react-router-dom';
import './admin-page.scss';

import OptionsMenu from './options-menu/options-menu';

function AdminPage() {
  return (
    <div>
      <div className="cms-container">
        <div className="cms-options-menu">
          <OptionsMenu />
        </div>

        <div className="cms-managment-panels">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
