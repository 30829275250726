import { MapLayerMouseEvent, Popup } from 'mapbox-gl';
import { HistoricVesselPoint } from '../../../maritime-menu-options/history-panel/historic-vessel-point.model';
import PopupHelpers from '../../../popup/popup.utils';

const popup = new Popup({
  closeButton: false,
  closeOnMove: true,
});

export function hideAISPositionInfoPopUp(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = '';
  popup.remove();
}

export function showAISPositionInfoPopup(e: MapLayerMouseEvent) {
  e.target.getCanvas().style.cursor = 'pointer';

  if (e.features?.length) {
    const feature = e.features[0];
    const properties = feature.properties as HistoricVesselPoint;
    const { mmsi, speed, timestamp, imo } = properties;

    // TODO: Use Name once available
    let name;

    PopupHelpers.createInfoCard(popup, [
      {
        label: 'Name',
        value: name,
      },
      {
        label: 'IMO',
        value: imo,
      },
      {
        label: 'MMSI',
        value: mmsi,
      },
      {
        label: 'Speed',
        value: `${speed} knots`,
      },
      {
        label: '',
        // TODO: move to date helpers
        value: new Date(timestamp)
          .toISOString()
          .replace('T', ' ')
          .substring(0, 19),
      },
    ]);

    // @ts-ignore
    // can't figure out the typing needed for coordinates
    popup.setLngLat(feature.geometry.coordinates);
    popup.addTo(e.target);
  }
}
