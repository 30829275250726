// import 'react-tabs/style/react-tabs.css';
import './vessel-information-label.scss';

interface VesselInformationLabelProps {
  title: string;
  data: string | number | boolean;
  dataStyle: any;
}

function VesselInformationLabel(props: VesselInformationLabelProps) {
  const { title, data, dataStyle } = props;

  return (
    <div
      data-testid="vessel-information-label"
      className="vessel-information-label"
    >
      <table className="vessel-information-label-table">
        <tbody className="vessel-information-label-table-body">
          <tr key={title + data}>
            <td className="vessel-information-label-title">{title}:</td>
            <td
              style={{ color: dataStyle }}
              className="vessel-information-label-data"
            >
              {data ? data.toString() : 'Unknown'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default VesselInformationLabel;
