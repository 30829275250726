import { configureStore } from '@reduxjs/toolkit';
import adminReducer from './admin/admin-page/admin.slice';
import menuReducer from './main-menu/menu.slice';
import mapReducer from './map/map.slice';
import alertingVesselsReducer from './maritime-menu-options/alert-panel/alertingVessels.slice';
import historyPanelReducer from './maritime-menu-options/history-panel/history-panel.slice';
import myFleetReducer from './maritime-menu-options/my-fleet-panel/myFleet.slice';
import vesselDossierReducer from './vessel-dossier/vessel-dossier.slice';

const store = configureStore({
  reducer: {
    alertingVessels: alertingVesselsReducer,
    menu: menuReducer,
    map: mapReducer,
    vesselDossier: vesselDossierReducer,
    historyPanel: historyPanelReducer,
    myFleet: myFleetReducer,
    admin: adminReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // map: make map instance accessible to entire app. Allows us to perform map actions and listen to map events
        // selectedVessel: mapbox geojson feature - will allow us to modify properties or perform action on selected feature
        ignoredActions: ['map/setMap', 'vessel-dossier/setSelectedVessel'],
        ignoredPaths: ['map.map', 'vesselDossier.selectedVessel'],
      },
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
