/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum EMenuItems {
  NOTHING_SELECTED = '',
  ALERTS = 'Alerts',
  HISTORY = 'History',
  MYF = 'My Fleet',
  OPEN_SEA_MAP = 'Open Sea Maps',
}

export const INITIAL_MENU_STATE = {
  menuOpen: false,
  selectedOption: EMenuItems.NOTHING_SELECTED,
  secondaryMenuOpen: false,
  vesselDossierOpen: false,
};

export const MenuSlice = createSlice({
  name: 'menu',
  initialState: INITIAL_MENU_STATE,
  reducers: {
    toggleMainMenu: (state) => {
      state.menuOpen = !state.menuOpen;
    },
    closeMenus: (state) => {
      state.menuOpen = false;
      state.secondaryMenuOpen = false;
    },
    toggleSecondaryMenu: (state, action) => {
      if (action.payload) {
        state.secondaryMenuOpen = action.payload;
        state.menuOpen = false;
      } else {
        state.secondaryMenuOpen = action.payload;
        state.selectedOption = EMenuItems.NOTHING_SELECTED;
      }
    },
    toggleVesselDossier: (state) => {
      state.vesselDossierOpen = !state.vesselDossierOpen;
    },
    setSelectedOption: (state, action) => {
      state.secondaryMenuOpen = true;
      state.selectedOption = action.payload;
    },
    setVesselDossierOpen: (state, action: PayloadAction<boolean>) => {
      state.vesselDossierOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function

export const {
  toggleMainMenu,
  closeMenus,
  toggleSecondaryMenu,
  toggleVesselDossier,
  setSelectedOption,
  setVesselDossierOpen,
} = MenuSlice.actions;

export default MenuSlice.reducer;
