import './simple-vessel-table.scss';

interface SimpleVesselTableProps {
  columnHeaders: string[];
  data: any[];
  dataFilter: (vessel: any) => boolean;
}

function SimpleVesselTable(props: SimpleVesselTableProps) {
  const { columnHeaders, data, dataFilter } = props;

  const dataToDisplay = data.filter(dataFilter);

  return (
    <table className="simple-vessel-table">
      <thead>
        <tr>
          {columnHeaders.map((header) => (
            <th>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {dataToDisplay.length > 0 ? (
          dataToDisplay.map((vessel) => (
            // TODO - add mapbox integration to pan and highlight selected vessel
            <tr key={vessel.mmsi}>
              {Object.keys(vessel).map((key) => (
                <td>{vessel[key]}</td>
              ))}
            </tr>
          ))
        ) : (
          <tr key="0123">
            <td colSpan={columnHeaders.length}>No data matches this filter.</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default SimpleVesselTable;
