const OpenSeaMapsBanners = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        coordinates: [
          [
            [-180, 80],
            [-180, 79],
            [180, 79],
            [180, 80],
            [-180, 80],
          ],
        ],
        type: 'Polygon',
      },
    },
    {
      type: 'Feature',
      geometry: {
        coordinates: [
          [
            [-180, -80],
            [180, -80],
            [180, -79],
            [-180, -79],
            [-180, -80],
          ],
        ],
        type: 'Polygon',
      },
    },
  ],
};

export default OpenSeaMapsBanners;
