/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from 'mapbox-gl';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import MapLayerManager from './map-layer-manager/map-layer-manager.utils';
import './map.scss';
import { setLatLon, setMap, setMapInitialised, setZoom } from './map.slice';

// NB: only use the mapbox web worker running Application builds
if (process.env.NODE_ENV === 'production') {
  // ^^ this is set to "production" automatically when application is built

  // @ts-ignore
  mapboxgl.workerClass =
    require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line global-require
}

function Map() {
  const MAP_CONTAINER_ID = 'map-container';

  const dispatch = useAppDispatch();
  const { lat, lon, zoom, style } = useAppSelector((state) => state.map);

  useEffect(() => {
    const map = new mapboxgl.Map({
      // ideally we would use ref for this, but TS flags this as an error here since ref values can potentially be null...
      container: MAP_CONTAINER_ID,
      accessToken:
        'pk.eyJ1IjoiZ2VvbGxlY3RyeWFuIiwiYSI6ImNrMDR6OHFjYjBwYWkzZ3BudTBmajkxa2UifQ.uDzV5Sqjq4R5gUKjnl7WFw',
      style,
      center: [lon, lat],
      zoom,
    });

    // TODO: possibly move this into a Widget/Control Manager.
    map.addControl(
      new mapboxgl.NavigationControl({
        showCompass: false,
        showZoom: true,
      }),
      'bottom-right'
    );

    map.on('move', () => {
      const center = map.getCenter();

      dispatch(
        setLatLon({
          lat: center.lat.toFixed(4),
          lon: center.lng.toFixed(4),
        })
      );

      dispatch(setZoom(map.getZoom().toFixed(2)));
    });

    dispatch(setMap(map));

    map.on('load', () => {
      MapLayerManager.loadAllLayers(map).then(() => {
        dispatch(setMapInitialised(true));
      });
    });

    return () => {
      // destroy map on component unmount
      map.remove();
      dispatch(setMapInitialised(false));
    };
  }, []);

  return <div id={MAP_CONTAINER_ID} data-testid={MAP_CONTAINER_ID} />;
}

export default Map;
